export default {
  CASE_DETAILS: {
    OK: 'Ok',
    CASE_ID: 'Ügyazonosító:',
    COPIED: 'Másolva',
    DEBTOR_DATA: {
      TITLE: 'Adós adatai',
      NAME_LABEL: 'Adós neve',
      ADDRESS_LABEL: 'Adós címe',
      EMAIL_LABEL: 'Adós email címe',
      PHONE_LABEL: 'Adós telefonszáma',
    },
    LEGAL: {
      TITLE: 'Jogviszony igazolása',
      FILES_LABEL: 'Feltöltött fájlok',
      DESCRIPTION_LABEL: 'Szöveges indoklás',
    },
    CLAIMS: {
      TITLE: 'Követelések',
      TOTAL_LABEL: 'Teljes követelés',
      CAPITAL_CLAIMS: {
        TITLE: 'Tőkekövetelések:',
        INDEX: '{{index}}. követelés',
        DUE_DATE_LABEL: 'Lejárt:',
        FILE_TITLE: 'Feltöltve:',
        TOTAL_LABEL: 'Összes tőkekövetelés',
      },
      OPTIONAL_CLAIMS: {
        TITLE: 'Járulékos követelések:',
        TOTAL_LABEL: 'Összes járulékos követelés',
      },
    },
    HISTORY: {
      TITLE: 'Ügy történet',
      STARTED_AT: 'Indítva:',
    },
    ERROR_MESSAGES: {
      UNKNOWN_ERROR_WHILE_LOADING_DETAILS: 'Valami hiba történt a részletek betöltésekor!'
    }
  },
};