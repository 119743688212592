<div class="flex wrapper">
  <ng-container *ngIf="!expandedElementDetails && this.isSpinnerEnabled">
    <mat-progress-spinner
      diameter="100"
      mode="indeterminate"
    ></mat-progress-spinner>
  </ng-container>
  <ng-container *ngIf="expandedElementDetails">
    <div class="left flex column grow1">
      <!-- Case id -->
      <div class="flex vcenter case-id">
        <div class="space-between grow1">
          <span color="blue-payee" class="data-label-bold">
            {{ "CASE_DETAILS.CASE_ID" | translate }}
          </span>
          <span color="blue-payee" class="data-value-bold">
            {{ expandedElementDetails.payee_case.payee_case_reference_id }}
          </span>
        </div>
        <button
          class="copy-button"
          payee-icon-button
          #tooltip="matTooltip"
          matTooltip="{{ 'CASE_DETAILS.COPIED' | translate }}"
          [matTooltipDisabled]="copyTooltipDisabled"
          matTooltipPosition="after"
          (click)="
            copyText(expandedElementDetails.payee_case.payee_case_reference_id)
          "
        >
          <payee-icon [name]="environment.COPY_ICON"></payee-icon>
        </button>
      </div>
      <hr />
      <!-- Debtor -->
      <ng-container>
        <div class="data-title" color="grey-dark">
          {{ "CASE_DETAILS.DEBTOR_DATA.TITLE" | translate }}
        </div>
        <div class="space-between">
          <div class="data-label">
            {{ "CASE_DETAILS.DEBTOR_DATA.NAME_LABEL" | translate }}
          </div>
          <div class="data-value">{{ expandedElementDetails.debtor.name }}</div>
        </div>
        <div class="space-between">
          <div class="data-label">
            {{ "CASE_DETAILS.DEBTOR_DATA.ADDRESS_LABEL" | translate }}
          </div>
          <div class="data-value">
            {{ expandedElementDetails.debtor.address }}
          </div>
        </div>
        <div *ngIf="expandedElementDetails.debtor.email" class="space-between">
          <div class="data-label">
            {{ "CASE_DETAILS.DEBTOR_DATA.EMAIL_LABEL" | translate }}
          </div>
          <div class="data-value">
            {{ expandedElementDetails.debtor.email }}
          </div>
        </div>
        <div *ngIf="expandedElementDetails.debtor.phone" class="space-between">
          <div class="data-label">
            {{ "CASE_DETAILS.DEBTOR_DATA.PHONE_LABEL" | translate }}
          </div>
          <div class="data-value">
            {{ expandedElementDetails.debtor.phone }}
          </div>
        </div>
      </ng-container>
      <!-- Legal base -->
      <ng-container
        *ngIf="
          expandedElementDetails.payee_case.legal_relationship_files.length >
            0 ||
          expandedElementDetails.payee_case.legal_relationship_specification ||
          expandedElementDetails.payee_case.description
        "
      >
        <ng-container
          *ngIf="
            expandedElementDetails.payee_case.legal_relationship_files.length >
            0
          "
        >
          <div class="data-title" color="grey-dark">
            {{ "CASE_DETAILS.LEGAL.TITLE" | translate }}
          </div>
          <div class="data-label-bold" color="grey-dark">
            {{ "CASE_DETAILS.LEGAL.FILES_LABEL" | translate }}
          </div>
          <ul>
            <li
              *ngFor="
                let legalRelationshipFile of expandedElementDetails.payee_case
                  .legal_relationship_files
              "
              class="data-value-bold"
              color="grey-dark"
            >
              <a
                [href]="legalRelationshipFile.storage_url"
                target="_blank"
                color="grey-dark"
                style="text-decoration: underline"
              >
                {{ legalRelationshipFile.file_name }}
              </a>
            </li>
          </ul>
        </ng-container>
        <hr
          *ngIf="
            expandedElementDetails.payee_case.legal_relationship_files.length >
              0 && expandedElementDetails.payee_case.description
          "
        />
        <ng-container *ngIf="expandedElementDetails.payee_case.description">
          <div class="data-label-bold" color="grey-dark">
            {{ "CASE_DETAILS.LEGAL.DESCRIPTION_LABEL" | translate }}
          </div>
          <div class="data-value">
            {{ expandedElementDetails.payee_case.description }}
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="right flex column grow1">
      <div class="data-title" color="grey-dark">
        {{ "CASE_DETAILS.CLAIMS.TITLE" | translate }}
      </div>
      <!--Claims-->
      <ng-container *ngIf="expandedElementDetails.claims.length > 0">
        <div class="data-label-bold" color="grey-dark">
          {{ "CASE_DETAILS.CLAIMS.CAPITAL_CLAIMS.TITLE" | translate }}
        </div>
        <div
          *ngFor="let claim of expandedElementDetails.claims; let i = index"
          class="flex column claim"
        >
          <div class="space-between vcenter">
            <div class="flex vcenter">
              <div class="data-label-bold">
                {{
                  "CASE_DETAILS.CLAIMS.CAPITAL_CLAIMS.INDEX"
                    | translate : { index: i + 1 }
                }}
              </div>
              <div class="data-label">
                &nbsp;-&nbsp;
                {{
                  "CASE_DETAILS.CLAIMS.CAPITAL_CLAIMS.DUE_DATE_LABEL"
                    | translate
                }}
                {{ claim.due_date_at | date : "yyyy.MM.dd." }}
              </div>
            </div>
            <div class="data-value">
              <span>
                {{ claim.remaining_amount | number : "1.0" : "hu" }}
                {{ claim.currency }}
              </span>
              <span color="grey-dark">
                &nbsp;/&nbsp;
                {{ claim.original_amount | number : "1.0" : "hu" }}
                {{ claim.currency }}
              </span>
            </div>
          </div>
          <ng-container *ngFor="let claimFile of claim.files">
            <div class="flex vcenter">
              <div class="data-label" color="grey-dark">
                {{
                  "CASE_DETAILS.CLAIMS.CAPITAL_CLAIMS.FILE_TITLE" | translate
                }}&nbsp;
              </div>
              <a
                class="data-value-bold"
                color="grey-dark"
                style="text-decoration: underline"
                [href]="claimFile.storage_url"
                target="_blank"
              >
                {{ claimFile.file_name }}
              </a>
            </div>
          </ng-container>
        </div>
        <div class="space-between">
          <div class="data-title-small">
            {{ "CASE_DETAILS.CLAIMS.CAPITAL_CLAIMS.TOTAL_LABEL" | translate }}
          </div>
          <div class="data-title-small">
            <span>
              {{
                expandedElementDetails.payee_case
                  .displayed_claims_remaining_amount | number : "1.0" : "hu"
              }}
              {{ expandedElementDetails.payee_case.currency }}
            </span>
            <span color="grey-dark">
              &nbsp;/&nbsp;
              {{
                expandedElementDetails.payee_case
                  .displayed_claims_original_amount | number : "1.0" : "hu"
              }}
              {{ expandedElementDetails.payee_case.currency }}
            </span>
          </div>
        </div>
      </ng-container>
      <!--Optional claims-->
      <ng-container *ngIf="expandedElementDetails.hiddenClaims.length > 0">
        <hr *ngIf="expandedElementDetails.claims.length > 0" />
        <div class="data-label-bold" color="grey-dark">
          {{ "CASE_DETAILS.CLAIMS.OPTIONAL_CLAIMS.TITLE" | translate }}
        </div>
        <div
          *ngFor="
            let claim of expandedElementDetails.hiddenClaims;
            let i = index
          "
          class="flex column claim"
        >
          <div class="space-between vcenter">
            <div class="data-label-bold">{{ claim.label }}</div>
            <div class="data-value">
              <span>
                {{ claim.remaining_amount | number : "1.0" : "hu" }}
                HUF
              </span>
              <span color="grey-dark">
                &nbsp;/&nbsp;
                {{ claim.original_amount | number : "1.0" : "hu" }}
                HUF
              </span>
            </div>
          </div>
        </div>
        <div class="space-between">
          <div class="data-title-small">
            {{ "CASE_DETAILS.CLAIMS.OPTIONAL_CLAIMS.TOTAL_LABEL" | translate }}
          </div>
          <div class="data-title-small">
            <span>
              {{
                expandedElementDetails.payee_case.hidden_claims_remaining_amount
                  | number : "1.0" : "hu"
              }}
              {{ expandedElementDetails.payee_case.currency }}
            </span>
            <span color="grey-dark">
              &nbsp;/&nbsp;
              {{
                expandedElementDetails.payee_case.hidden_claims_original_amount
                  | number : "1.0" : "hu"
              }}
              {{ expandedElementDetails.payee_case.currency }}
            </span>
          </div>
        </div>
      </ng-container>
      <hr />
      <div class="space-between">
        <div class="data-title">
          {{ "CASE_DETAILS.CLAIMS.TOTAL_LABEL" | translate }}
        </div>
        <div class="data-title">
          <span>
            {{
              expandedElementDetails.payee_case.remaining_amount
                | number : "1.0" : "hu"
            }}
            {{ expandedElementDetails.payee_case.currency }}
          </span>
          <span color="grey-dark">
            &nbsp;/&nbsp;
            {{
              expandedElementDetails.payee_case.original_amount
                | number : "1.0" : "hu"
            }}
            {{ expandedElementDetails.payee_case.currency }}
          </span>
        </div>
      </div>
      <!--History-->
      <ng-container *ngIf="showParent">
        <div class="data-title">
          {{ "CASE_DETAILS.HISTORY.TITLE" | translate }}
        </div>
        <div
          *ngFor="
            let history of expandedElementDetails.payee_case.history;
            let i = index
          "
          class="flex vcenter parent-row"
        >
          <div class="data-label-bold">{{ i + 1 }}.</div>
          <a class="data-value" (click)="filterToHistoricCase(history)">
            {{ history.payee_case_reference_id }}
          </a>
          <div class="data-value">
            {{ history.stage_label }}
          </div>
          <div class="data-value" *ngIf="history.started_at">
            {{ "CASE_DETAILS.HISTORY.STARTED_AT" | translate }}
            {{ history.started_at | date : "yyyy.MM.dd. HH:mm" }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
