<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <h1>Kérlek, add meg itt a hiányzó adatokat</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      <span>
        Az email mellékletében megtalálod, hogy milyen adatot hiányol a
        közjegyző.
        <b>
          Kérlek, írd meg ezt az adatot az alábbi mezőbe, és az ügy folytatódik
          is.
        </b>
      </span>
    </div>
  </ng-template>

  <form [formGroup]="missingDataGeneral">
    <payee-form-field
      [width]="'wider'"
      [height]="'taller'"
    >
      <textarea
        payee-input
        placeholder="Írd ide a saját szavaiddal..."
        formControlName="message"
      ></textarea>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>Elküldöm</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
