import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyCaseWithProductDialogComponent } from './copy-case-with-product-dialog.component';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';



@NgModule({
  declarations: [
    CopyCaseWithProductDialogComponent
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    PayeeInfoModule,
  ]
})
export class CopyCaseWithProductDialogModule { }
