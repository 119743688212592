import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentDeadlineDialogComponent } from './attachment-deadline-dialog.component';

import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    AttachmentDeadlineDialogComponent
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
    PayeeFormFieldsModule,
    PayeeInputModule,
    PayeeDropzoneModule,
    PayeeButtonsModule,
    ReactiveFormsModule,
    FormsModule,
    PayeeIconModule,
    MatProgressSpinnerModule,
  ]
})
export class AttachmentDeadlineDialogModule { }
