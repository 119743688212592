import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { CampaignsComponent } from './campaigns.component';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CampaignUserTableComponent } from './campaign-user-table/campaign-user-table.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CampaignsComponent,
    CampaignUserTableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    FormsModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
  ],
})
export class CampaignsModule { }
