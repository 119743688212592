import { Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { PayeeSwitcherButtonComponent } from '../payee-buttons/payee-switcher-button/payee-switcher-button.component';

@Component({
	selector: 'payee-switcher',
	templateUrl: './payee-switcher.component.html',
	styleUrls: ['./payee-switcher.component.scss'],
})
export class PayeeSwitcherComponent {
	// tslint:disable-next-line: variable-name
	private _buttons!: QueryList<PayeeSwitcherButtonComponent>;
	@ContentChildren(PayeeSwitcherButtonComponent) set buttons(buttons: QueryList<PayeeSwitcherButtonComponent>) {
		this._buttons = buttons;
		setTimeout(() => this.updateButtons(), 0);
	}
	get buttons() { return this._buttons; }

	// tslint:disable-next-line: variable-name
	private _switcherGroup!: ElementRef<HTMLDivElement>;
	@ViewChild('payeeSwitcherGroup') set switcherGroup(switcherGroup: ElementRef<HTMLDivElement>) {
		this._switcherGroup = switcherGroup;
		this.updateGroup();
	}
	get switcherGroup() { return this._switcherGroup; }

	@Input() disabled = false;
	@Input() required = false;

	private _selected?: PayeeSwitcherButtonComponent;
	@Input() set selected(selected: PayeeSwitcherButtonComponent | undefined) {
		this._selected = selected;
		this.selectedChange.emit(selected);
		this.value = selected?.value;
		setTimeout(() => this.updateButtons(), 0);
	}
	get selected() { return this._selected; }
	@Output() selectedChange = new EventEmitter<any>();

	private _value?: any;
	@Input() set value(value: any) {
		this._value = value;
		this.valueChange.emit(value);
		setTimeout(() => this.updateButtons(), 0);
	}
	get value() { return this._value; }
	@Output() valueChange = new EventEmitter<any>();

	// tslint:disable-next-line: variable-name
	private _vertical = false;
	@Input() set vertical(vertical: boolean) {
		this._vertical = !!vertical;
		this.updateGroup();
		this.updateButtons();
	}
	get vertical() { return this._vertical; }

	private updateGroup() {
		if (this.switcherGroup?.nativeElement) {
			if (this.vertical) {
				this.switcherGroup.nativeElement.classList.add('column');
			} else {
				this.switcherGroup.nativeElement.classList.remove('column');
			}
		}
	}

	private updateButtons() {
		if (!this.selected) {
			this.selectDefault();
		}
		this.setBorderOfButtons();
		this.setColorOfButtons();
		this.setupOnClickListeners();
	}

	private selectDefault() {
		if (!this.buttons?.length) {
			return;
		}
		if (!this.value) {
			this.selected = this.buttons.first;
			setTimeout(() => this.value = this.selected?.value, 0);
		} else {
			this.selected = this.buttons.find(button => button.value === this.value);
		}
	}

	private setBorderOfButtons() {
		if (!this.buttons?.length) {
			return;
		}

		// Remove borders
		this.buttons.forEach(button => button.element.classList.remove('top', 'bottom', 'left', 'right'));

		if (this.vertical) {
			this.buttons.first.element.classList.add('top');
			this.buttons.last.element.classList.add('bottom');
		} else {
			this.buttons.first.element.classList.add('left');
			this.buttons.last.element.classList.add('right');
		}
	}

	private setColorOfButtons() {
		this.buttons?.forEach(button => {
			if (this.selected?.id === button.id) {
				button.element.setAttribute('color', 'blue-payee');
			} else {
				button.element.setAttribute('color', 'white');
			}
		});
	}

	private setupOnClickListeners() {
		this.buttons?.forEach(button => {
			button.element.onclick = () => {
				if (this.selected === button) {
					return;
				}
				this.selected = button;
				this.selectedChange.emit(button);
				this.setColorOfButtons();

				if (this.value === button.value) {
					return;
				}
				this.value = button.value;
				this.valueChange.emit(this.value);
			};
		});
	}
}
