import { Component, Input } from '@angular/core';
import payeeIconConfig from './payee-icon-config';

@Component({
	selector: 'payee-icon',
	templateUrl: './payee-icon.component.html',
	styleUrls: ['./payee-icon.component.scss'],
})
export class PayeeIconComponent {
	@Input() name?: string;
	@Input() width?: string = '24px';
	@Input() height?: string = '24px';

	get iconPath() {
		if (!this.name) {
			return null;
		}
		return (payeeIconConfig as Record<string, string>)[this.name] || null;
	}
}
