import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'payee-popup',
	templateUrl: './payee-popup.component.html',
	styleUrls: ['./payee-popup.component.scss'],
})
export class PayeePopupComponent {
  @Input() title?: string;
  @Input() description?: string;

  @ContentChild('payeePopupTitleTemplate') titleTemplateRef?: TemplateRef<unknown>;
  @ContentChild('payeePopupDescriptionTemplate') descriptionTemplateRef?: TemplateRef<unknown>;

  @Input() onSubmit?: () => void;
  @ContentChild('payeePopupSubmitTemplate') submitTemplateRef?: TemplateRef<unknown>;
  @Input() onCancel?: () => void;
  @ContentChild('payeePopupCancelTemplate') cancelTemplateRef?: TemplateRef<unknown>;
}