<payee-popup title="Státusz megváltoztatása">
  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      Megváltoztatom az ügy státuszát
    </button>
  </ng-template>

  <payee-dropdown
    width="wide"
    [formControl]="stateForm"
    [options]="stateOptions"
    [displayWith]="stateDisplayWith"
  ></payee-dropdown>

  <mat-error *ngIf="stateForm.invalid && stateForm.dirty">
    Státusz kiválasztása kötelező!
  </mat-error>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      color="white"
      [isBorderButton]="true"
      width="wide"
      mat-dialog-close
    >
      Mégsem
    </button>
  </ng-template>
</payee-popup>
