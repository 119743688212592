<app-header-app routerLink="/login"></app-header-app>
<app-form-template title="Új jelszó kérése" [submit]="sendEmail" [formGroup]="forgotPasswordForm">
  <payee-form-field width="wide">
    <span payee-label>Először is add meg az email címedet</span>
    <input payee-input formControlName="email">
    <ng-container payee-error *ngIf="email.dirty">
      <mat-error *ngIf="email.hasError('email')">Létező email címet adj meg</mat-error>
      <mat-error *ngIf="email.hasError('required')">A mező kitöltése kötelező</mat-error>
    </ng-container>
  </payee-form-field>

  <button payee-button width="wide" [disabled]=loading>
    <ng-container *ngIf="!loading">
      Új jelszót kérek
    </ng-container>
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
</app-form-template>