import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CasesService } from 'src/app/services/cases/cases.service';
import { Product, ProductService } from 'src/app/services/product-service/product-service.service';

@Component({
  selector: 'app-copy-case-with-product-dialog',
  templateUrl: './copy-case-with-product-dialog.component.html',
  styleUrls: ['./copy-case-with-product-dialog.component.scss']
})
export class CopyCaseWithProductDialogComponent implements OnInit {
  readonly productControl: FormControl;
  productOptions: Product[] = [];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private caseId: string,
    private dialogRef: MatDialogRef<CopyCaseWithProductDialogComponent>,
    private fb: FormBuilder,
    private casesService: CasesService,
    private products: ProductService,
  ) {
    this.productControl = this.fb.control(null, [Validators.required]);
  }

  async ngOnInit(): Promise<void> {
    const result = await this.products.getProducts();
    this.productOptions = result.products.filter(p => p.type !== 'hard_2');
  }

  async submit(): Promise<void> {
    if (this.productControl.invalid) {
      this.productControl.markAsDirty();
      return;
    }

    try {
      this.loading = true;
      const product: Product = this.productControl.value;
      const result = await this.casesService.copyCaseWithProduct(this.caseId, product.id);
      this.dialogRef.close(result);
    } catch (error) {
      this.loading = false;
      console.error('Error while copying case', error);
    }
  }

  productDisplayWith(p?: Product): string {
    return p?.name ?? '';
  }
}
