import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginTemplateComponent } from './login-template.component';

import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeHeaderModule } from 'src/app/shared/payee-header/payee-header.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';
import { FormTemplateModule } from '../screen-templates/form-template/form-template.module';

@NgModule({
  declarations: [
    LoginTemplateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormTemplateModule,
    MatCheckboxModule,
    MatFormFieldModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
    PayeeHeaderModule,
    PayeeIconModule,
    PayeeInputModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    LoginTemplateComponent,
  ],
})
export class LoginTemplateModule { }
