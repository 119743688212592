import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'button[payee-copy-button]',
	templateUrl: './payee-copy-button.component.html',
	styleUrls: ['./payee-copy-button.component.scss'],
})
export class PayeeCopyButtonComponent {
	@Input() input?: HTMLInputElement | HTMLTextAreaElement;
	@Input() disableRipple = false;
	@Input() color = 'black';
	@Input() @HostBinding('attr.size') size = 'normal';

	get element() { return this.ref.nativeElement; }

	constructor(
		private ref: ElementRef<HTMLButtonElement>,
	) {
		this.ref.nativeElement.addEventListener('click', () => this.copyTextToClipboard());
	}

	copyTextToClipboard() {
		if (!this.input) {
			return;
		}

		this.input.select();
		this.input.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(this.input.value);
	}
}
