import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeRadioButtonComponent } from './payee-radio-button/payee-radio-button.component';
import { PayeeRadioGroupComponent } from './payee-radio-group/payee-radio-group.component';
import { PayeeButtonsModule } from '../payee-buttons/payee-buttons.module';

@NgModule({
	declarations: [
		PayeeRadioButtonComponent,
		PayeeRadioGroupComponent,
	],
	imports: [
		CommonModule,
		PayeeButtonsModule,
	],
	exports: [
		PayeeRadioButtonComponent,
		PayeeRadioGroupComponent,
		CommonModule,
	],
})
export class PayeeRadioModule { }
