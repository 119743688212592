import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-start-execution-dialog',
  templateUrl: './start-execution-dialog.component.html',
  styleUrls: ['./start-execution-dialog.component.scss']
})
export class StartExecutionDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<StartExecutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RunTransition,
  ) { }

  submit(): void {
    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    };

    this.dialogRef.close(closeValue);
  }
}
