<payee-popup title="Hitelező szerkesztése">
  <mat-progress-spinner
    *ngIf="initLoading"
    color="accent"
    mode="indeterminate"
    diameter="100"
  ></mat-progress-spinner>
  <form *ngIf="!initLoading" [formGroup]="formGroup">
    <!-- Client name -->
    <payee-form-field width="wide">
      <span payee-label>Név</span>
      <input payee-input formControlName="name" />
    </payee-form-field>
    <!-- Tax Number -->
    <payee-form-field width="wide">
      <span payee-label>Adószám</span>
      <input payee-input formControlName="tax_number" />
    </payee-form-field>
    <!-- Client type -->
    <payee-form-field width="wide">
      <span payee-label>Hitelező típusa</span>
      <payee-autocomplete
        [options]="partyTypeOptions"
        formControlName="party_type_id"
        [displayWith]="partyTypeDisplayWith"
      >
      </payee-autocomplete>
      <ng-container
        payee-error
        *ngIf="party_type_id.dirty && party_type_id.invalid"
      >
        <span *ngIf="party_type_id.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="party_type_id.hasError('backend')">
          {{ party_type_id.getError("backend") }}
        </span>
      </ng-container>
    </payee-form-field>
    <!-- Client address -->
    <form [formGroup]="address">
      <h2>{{ name.value || "Hitelező" }} kézbesítési címe</h2>
      <!-- Country -->
      <payee-form-field width="wide">
        <span payee-label>Ország</span>
        <payee-autocomplete
          [options]="countryOptions"
          formControlName="country"
          [displayWith]="countryDisplayWith"
        >
        </payee-autocomplete>
        <ng-container payee-error *ngIf="country.dirty && country.invalid">
          <span *ngIf="country.hasError('required')">
            A mező kitöltése kötelező
          </span>
          <span *ngIf="country.hasError('backend')">
            {{ country.getError("backend") }}
          </span>
        </ng-container>
      </payee-form-field>
      <div class="flex" style="justify-content: space-between">
        <!-- Postcode -->
        <payee-form-field style="--width: 192px">
          <span payee-label>Irányítószám</span>
          <input payee-input formControlName="postcode" />
          <ng-container *ngIf="postcode.dirty" payee-error>
            <span *ngIf="postcode.hasError('required')">
              A mező kitöltése kötelező
            </span>
          </ng-container>
        </payee-form-field>
        <!-- Settlement -->
        <payee-form-field style="--width: 192px">
          <span payee-label>Település</span>
          <input payee-input formControlName="settlement" />
          <ng-container *ngIf="settlement.dirty" payee-error>
            <span *ngIf="settlement.hasError('required')">
              A mező kitöltése kötelező
            </span>
          </ng-container>
        </payee-form-field>
      </div>
      <!-- Street -->
      <payee-form-field width="wide">
        <span payee-label>Utca és házszám</span>
        <input payee-input formControlName="street" />
        <ng-container *ngIf="street.dirty" payee-error>
          <span *ngIf="street.hasError('required')">
            A mező kitöltése kötelező
          </span>
        </ng-container>
      </payee-form-field>
    </form>
    <!-- Client bank account -->
    <form [formGroup]="bankAccount">
      <h2>{{ name.value || "Hitelező" }} bankszámlája</h2>
      <payee-form-field width="wide">
        <span payee-label>Számlaszám</span>
        <input payee-input formControlName="account_number" />
        <ng-container
          payee-error
          *ngIf="account_number.dirty && account_number.invalid"
        >
          <span *ngIf="account_number.hasError('pattern')"> Helytelen formátum! </span>
          <span *ngIf="account_number.hasError('backend')">
            {{ account_number.getError("backend") }}
          </span>
        </ng-container>
      </payee-form-field>
      <payee-form-field width="wide">
        <span payee-label>IBAN</span>
        <input payee-input formControlName="iban" />
        <ng-container payee-error *ngIf="iban.dirty && iban.invalid">
          <span *ngIf="iban.hasError('pattern')"> Helytelen formátum! </span>
          <span *ngIf="iban.hasError('backend')">
            {{ iban.getError("backend") }}
          </span>
        </ng-container>
      </payee-form-field>
      <payee-form-field width="wide">
        <span payee-label>BIC</span>
        <input payee-input formControlName="bic" />
        <ng-container payee-error *ngIf="bic.dirty && bic.invalid">
          <span *ngIf="bic.hasError('backend')">
            {{ bic.getError("backend") }}
          </span>
        </ng-container>
      </payee-form-field>
      <payee-form-field width="wide">
        <span payee-label>Bank neve</span>
        <input payee-input formControlName="bank_name" />
        <ng-container payee-error *ngIf="bank_name.dirty && bank_name.invalid">
          <span *ngIf="bank_name.hasError('backend')">
            {{ bank_name.getError("backend") }}
          </span>
        </ng-container>
      </payee-form-field>
      <payee-form-field width="wide">
        <span payee-label>Kedvezményezett neve</span>
        <input payee-input formControlName="account_holder_name" />
        <ng-container
          payee-error
          *ngIf="account_holder_name.dirty && account_holder_name.invalid"
        >
          <span *ngIf="account_holder_name.hasError('backend')">
            {{ account_holder_name.getError("backend") }}
          </span>
        </ng-container>
      </payee-form-field>
    </form>
    <!-- Errors that didn't match any fields in the form -->
    <div
      color="alert-red"
      payee-error
      *ngIf="formGroup.dirty && formGroup.invalid"
    >
      <span *ngIf="formGroup.hasError('backend')">
        {{ formGroup.getError("backend") }}
      </span>
    </div>
  </form>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      [disabled]="formGroup?.invalid"
      (click)="submit()"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close="">
      Mégse
    </button>
  </ng-template>
</payee-popup>
