<payee-popup
  description="A fizetési meghagyás jogerőre emelkedett, így megindítható a végrehajtási eljárás. Ennek keretében egy végrehajtó kerül megbízásra, aki felhívja az adóst a teljesítésre, és ha ez nem történik meg, akkor az adós vagyonát felkutatja, és, például inkasszózással, elvonja."
>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      id="title-icon"
      name="pencil"
      height="71.43px"
      width="60.71px"
    ></payee-icon>
    <h1 id="title-text">Végrehajtás elindítása</h1>
  </ng-template>
  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      Elindítom a végrehajtást
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      color="white"
      [isBorderButton]="true"
      width="wide"
      mat-dialog-close
    >
      Mégsem
    </button>
  </ng-template>
</payee-popup>
