<payee-popup title="Dokumentumok generálása">
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      color="alert-green"
      width="wide"
      (click)="submit()"
    >
      Generálás
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      color="alert-red"
      width="wide"
      mat-dialog-close
    >
      Mégse
    </button>
  </ng-template>
</payee-popup>
