<div class="wrapper">
  <ng-container *ngIf="!caseLogs && this.isSpinnerEnabled">
    <mat-progress-spinner
      diameter="100"
      mode="indeterminate"
    ></mat-progress-spinner>
  </ng-container>
  <ng-container *ngIf="!!caseLogs">
    <a class="show-all-events" (click)="showAllLogs = !showAllLogs">
      <ng-container *ngIf="!showAllLogs">
         {{ 'CASE_EVENTS.SHOW_ALL_EVENTS' | translate }}
      </ng-container>
      <ng-container *ngIf="showAllLogs">
        {{ 'CASE_EVENTS.SHOW_LESS_EVENTS' | translate }}
      </ng-container>
    </a>
    <ng-container *ngFor="let caseLog of logsOfExpandedElement">
      <div class="event-date" #eventDate>
        {{ caseLog.happenedAt | date : "yyyy.MM.dd. HH:mm" }}
      </div>
      <div
        class="event-dot"
        [class.done]="isEventDone(caseLog)"
        [class.first]="isEventFirstDone(caseLog) || isEventFirstFuture(caseLog)"
        [class.error]="caseLog?.status === 'error'"
      ></div>
      <div class="event-label" [class.lastDone]="isEventLastDone(caseLog)">
        {{ caseLog.label }}
      </div>
      <div *ngIf="caseLog.payments?.length" class="transition-payments">
        <div class="transition-payment-title" color="grey-dark">
          {{ "CASE_EVENTS.PAYMENTS_OF_EVENT" | translate }}
        </div>
        <div
          *ngFor="let payment of caseLog.payments"
          class="transition-payment flex vcenter"
        >
          <span>{{ payment.label }}:&nbsp;{{ payment.amount | number : "1.0" : "hu" }}&nbsp;{{ payment.currency_iso }}</span>
          <span *ngIf="payment.original_amount" color="grey-dark">
            &nbsp;/&nbsp;{{ payment.original_amount | number : "1.0" : "hu" }}&nbsp;{{ payment.currency_iso }}
          </span>
        </div>
      </div>
      <div *ngIf="caseLog.files?.length" class="transition-files">
        <div class="transition-file-title" color="grey-dark">
          {{ "CASE_EVENTS.DOCUMENTS_OF_EVENT" | translate }}
        </div>
        <a
          *ngFor="let file of caseLog.files"
          [href]="downloadUrlOfFile(file)"
          target="_blank"
          class="transition-file flex vcenter"
          color="blue-king"
        >
          <span>{{ file.file_name }}</span>
          <payee-icon
            [name]="environment.EXTERNAL_LINK_ICON"
            height="15px"
          ></payee-icon>
        </a>
      </div>
    </ng-container>
  </ng-container>
</div>
