<payee-popup>
  <ng-template #payeePopupTitleTemplate>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
  </ng-template>

  <lottie-player 
    src="/assets/98288-loading.json" 
    background="transparent" speed="1" autoplay loop
    style="width: 264x; height: 264px;">
  </lottie-player>

  <ng-template #payeePopupSubmitTemplate>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
  </ng-template>
</payee-popup>
