export default {
  CASE_EVENTS: {
    SHOW_ALL_EVENTS: 'Show all events',
    SHOW_LESS_EVENTS: 'Show less events',
    PAYMENTS_OF_EVENT: 'Payment related to the event:',
    DOCUMENTS_OF_EVENT: 'Document related to the event:',
    OK: 'OK',
    ERROR_MESSAGES: {
      UNKNOWN_ERROR_WHILE_LOADING_EVENTS: 'Error while loading the events! Please try again.',
    }
  },
};