import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type CampaignItem = {
  utm_content: string;
  created_at: string | null;
};

export type GetCampaignsResponse = {
  campaigns: CampaignItem[];
};

export type CreateCampaignParams = {
  utm_content: string;
}

export type CreateCampaignResponse = {
  campaign: CampaignItem;
}

export type CampaignUserItem = {
  name: string | null;
  email: string;
  payee_cases_count: number;
};

export type GetUsersOfCampaignResponse = {
  users: CampaignUserItem[];
};

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  async createCampaign(params: CreateCampaignParams): Promise<CreateCampaignResponse> {
    const url = `${environment.baseUrl}/api/campaign`;
    const result = await axios.post<CreateCampaignResponse>(url, params);
    return result.data;
  }

  async getCampaigns(): Promise<GetCampaignsResponse> {
    const url = `${environment.baseUrl}/api/campaign`;
    const result = await axios.get<GetCampaignsResponse>(url);
    return result.data;
  }

  async getUsersOfCampaign(campaign: string): Promise<GetUsersOfCampaignResponse> {
    const url = `${environment.baseUrl}/api/campaign-user/${campaign}`;
    const result = await axios.get<GetUsersOfCampaignResponse>(url);
    return result.data;
  }
}
