import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pulsating-ring',
  templateUrl: './pulsating-ring.component.html',
  styleUrls: ['./pulsating-ring.component.scss']
})
export class PulsatingRingComponent {
  @Input() color: string;
}
