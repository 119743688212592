import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header-empty',
  templateUrl: './header-empty.component.html',
  styleUrls: ['./header-empty.component.scss']
})
export class HeaderEmptyComponent {
  @Input() payeeLink = environment.HELP_URL // 'https://payee.tech';

  @ContentChild('logoTemplate') logoTemplateRef: TemplateRef<any>;
  @ContentChild('buttonTemplate') buttonTemplateRef: TemplateRef<any>;
}
