import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeAutocompleteComponent } from './payee-autocomplete/payee-autocomplete.component';
import { PayeeCopyFormFieldComponent } from './payee-copy-form-field/payee-copy-form-field.component';
import { PayeeDatepickerComponent } from './payee-datepicker/payee-datepicker.component';
import { PayeeDropdownComponent } from './payee-dropdown/payee-dropdown.component';
import { PayeeFormFieldComponent } from './payee-form-field/payee-form-field.component';
import { PayeePasswordFormFieldComponent } from './payee-password-form-field/payee-password-form-field.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import { PayeeInputModule } from '../payee-input/payee-input.module';
import { PayeeButtonsModule } from '../payee-buttons/payee-buttons.module';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';

class PayeeDateAdapter extends MomentDateAdapter {
	override getDayOfWeekNames(): string[] {
		return ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
	}

	override getMonthNames(): string[] {
		return [
			'Január', 'Február', 'Március', 'Április', 'Május', 'Június',
			'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December',
		];
	}
}

const DateFormats = {
	parse: {
		dateInput: ['YYYY.MM.DD.'],
	},
	display: {
		dateInput: 'YYYY.MM.DD.',
		monthYearLabel: 'YYYY.MM.DD.',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [
		PayeeAutocompleteComponent,
		PayeeCopyFormFieldComponent,
		PayeeDatepickerComponent,
		PayeeDropdownComponent,
		PayeeFormFieldComponent,
		PayeePasswordFormFieldComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		PayeeInputModule,
		PayeeButtonsModule,
		MatMenuModule,
		MatDatepickerModule,
		PayeeIconModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
	],
	exports: [
		PayeeAutocompleteComponent,
		PayeeCopyFormFieldComponent,
		PayeeDatepickerComponent,
		PayeeDropdownComponent,
		PayeeFormFieldComponent,
		PayeePasswordFormFieldComponent,
		CommonModule,
	],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: DateFormats },
		{ provide: DateAdapter, useClass: PayeeDateAdapter, deps: [MAT_DATE_LOCALE] },
	],
})
export class PayeeFormFieldsModule { }
