<form (submit)="onSubmit()" [formGroup]="formGroup" class="flex column vcenter">
  <ng-template #titleTemplate>
      <h1>{{title}}</h1>
  </ng-template>

  <div class="title">
    <ng-container [ngTemplateOutlet]="titleTemplateRef || titleTemplate">
    </ng-container>
  </div>

  <ng-content></ng-content>
</form>