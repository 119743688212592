<div class="payee-header-wrapper">
  <ng-template #logoTemplate>
    <img
      src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg"
      width="100px"
    />
  </ng-template>

  <div class="payee-header-logo">
    <ng-container
      [ngTemplateOutlet]="headerLogoTemplateRef || logoTemplate"
    ></ng-container>
  </div>
  <div class="payee-header-content">
    <ng-content></ng-content>
  </div>
  <div class="payee-header-button">
    <ng-container *ngIf="headerButtonTemplateRef">
      <payee-vertical-line style="height: 30px"></payee-vertical-line>
      <ng-container [ngTemplateOutlet]="headerButtonTemplateRef"></ng-container>
    </ng-container>
  </div>
</div>
