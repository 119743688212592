import {
	AfterContentInit,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	QueryList,
	SimpleChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PayeeRadioChange } from '../payee-radio-change';
import { PayeeRadioButtonComponent } from '../payee-radio-button/payee-radio-button.component';

@Component({
	selector: 'payee-radio-group',
	templateUrl: './payee-radio-group.component.html',
	styleUrls: ['./payee-radio-group.component.scss'],
})
export class PayeeRadioGroupComponent implements OnDestroy, OnChanges, AfterContentInit {
	// @Input() color?: string; // Color of radio buttons
	@Input() disabled?: boolean;
	@Input() labelPosition: 'before' | 'after' = 'after';
	@Input() required?: boolean;
	@Input() selected: PayeeRadioButtonComponent | null = null;
	@Input() value: any = null;

	@Output() change = new EventEmitter<PayeeRadioChange>();

	private radioSubKiller = new Subject<void>();
	private radioGroupSub!: Subscription;
	@ContentChildren(PayeeRadioButtonComponent) radioButtons!: QueryList<PayeeRadioButtonComponent>;

	ngOnChanges(changes: SimpleChanges): void {
		const radioButtons: QueryList<PayeeRadioButtonComponent> = changes?.['radioButtons']?.currentValue ?? this.radioButtons;
		const selected: PayeeRadioButtonComponent = changes?.['selected']?.currentValue ?? this.selected;
		const value: any = changes?.['value']?.currentValue ?? this.value;
		const disabled: boolean = changes?.['disabled']?.currentValue ?? this.disabled;

		this.radioButtons?.forEach(radioButton => {
			radioButton.disabled = disabled;
		});

		if ((selected || value) && radioButtons?.length) {
			this.selectButton({ selected, value, emitChanges: true });
		}
	}

	ngAfterContentInit(): void {
		if (this.selected) {
			this.selectButton({ selected: this.selected, value: undefined, emitChanges: true });
		} else if (this.value) {
			this.selectButton({ selected: undefined, value: this.value, emitChanges: true });
		} else {
			this.selectButton();
		}

		this.radioGroupSub = this.radioButtons.changes.subscribe({
			next: (radioButtons: QueryList<PayeeRadioButtonComponent>) => {
				this.radioSubKiller.next();
				radioButtons.forEach(radioButton => {
					if (this.labelPosition) {
						radioButton.labelPosition = this.labelPosition;
					}
					// if (this.color) {
					// 	radioButton.color = this.color;
					// }
					radioButton.change.pipe(takeUntil(this.radioSubKiller)).subscribe({
						next: () => {
							this.selectButton({ selected: radioButton, value: undefined, emitChanges: false });
						},
					});
				});
			},
		});

		this.radioButtons.notifyOnChanges();
	}

	ngOnDestroy(): void {
		this.radioSubKiller.next();
		this.radioSubKiller.complete();
		this.radioGroupSub.unsubscribe();
	}

	selectButton(data?:
		{ selected: PayeeRadioButtonComponent, value: undefined, emitChanges: boolean }
		| { selected: undefined, value: any, emitChanges: boolean }
	) {
		if (this.selected) {
			this.selected.checked = false;
		}

		if (!this.radioButtons?.length) {
			this.selected = null;
			this.value = null;
			this.change.emit();
			return;
		}

		if (!data) {
			this.selectButton({ selected: this.radioButtons.first, value: undefined, emitChanges: true });
			return;
		}

		let selected = data.selected;

		if (!selected) {
			const value = data.value;
			selected = this.radioButtons.find(radioButton => radioButton.value === value);
			if (!selected) {
				this.selectButton({ selected: this.radioButtons.first, value: undefined, emitChanges: true });
				return;
			}
		}

		if (this.radioButtons.find(item => item === selected)) {
			this.selected = selected;
			this.value = selected.value;
			selected.onSelect(true, data.emitChanges);
			this.change.emit({
				source: selected,
				value: selected.value,
			});
		} else {
			this.selectButton();
		}
	}
}
