<app-header-app xLink="/login"></app-header-app>
<app-form-template>
  <ng-template #titleTemplate>
    <div class="flex vcenter column title-wrapper">
      <img src="assets/shared/global-images/icons/checkmark-circle-green-big.svg" alt="checkmark-green-circle-big">
      <h1>Új jelszó sikeresen beállítva!</h1>
    </div>
  </ng-template>

  <span>
    Most már beléphetsz az új jelszavaddal.
  </span>

  <button payee-button width="wide" routerLink="/login">Tovább a belépésre</button>
</app-form-template>