import {
	AfterContentInit,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { PayeeProgressBarNextDirective } from './payee-progress-bar-next.directive';
import { PayeeProgressBarPrevDirective } from './payee-progress-bar-prev.directive';
import { PayeeStepComponent } from './payee-step/payee-step.component';

@Component({
	selector: 'payee-progress-bar',
	templateUrl: './payee-progress-bar.component.html',
	styleUrls: ['./payee-progress-bar.component.scss'],
})
export class PayeeProgressBarComponent implements AfterContentInit {
	@Input() xLink?: string;

	@ContentChild('headerLogoTemplate') headerLogoTemplateRef?: TemplateRef<any>;
	@ContentChild('headerButtonTemplate') headerButtonTemplateRef?: TemplateRef<any>;

	@ContentChildren(PayeeStepComponent) steps!: QueryList<PayeeStepComponent>;
	@ContentChildren(PayeeStepComponent, { read: ElementRef }) stepRefs!: QueryList<ElementRef<HTMLElement>>;
	private get stepElements() { return this.stepRefs?.map(ref => ref.nativeElement); }

	// tslint:disable-next-line: variable-name
	private _selectedIndex = 0;
	@Input() set selectedIndex(index: number) {
		this.selectIndex(index);
	}
	get selectedIndex() { return this._selectedIndex; }
	@Output() selectedIndexChange = new EventEmitter<number>();

	@ViewChild('selectorLine', { static: true }) selectorLine!: ElementRef<HTMLDivElement>;

	@ContentChildren(PayeeProgressBarPrevDirective, { read: ElementRef, descendants: true })
	set prevButtons(prevButtons: QueryList<ElementRef<HTMLElement>>) {
		prevButtons.forEach(button => {
			button.nativeElement.onclick = () => {
				--this.selectedIndex;
			};
		});
	}

	@ContentChildren(PayeeProgressBarNextDirective, { read: ElementRef, descendants: true })
	set nextButtons(nextButtons: QueryList<ElementRef<HTMLElement>>) {
		nextButtons.forEach(button => {
			button.nativeElement.onclick = () => {
				++this.selectedIndex;
			};
		});
	}

	// tslint:disable-next-line: variable-name
	private _linear = false;
	@Input() set linear(linear: boolean) {
		this._linear = linear;
	}
	get linear() { return this._linear; }
	get indexOfLastEnabledStep() {
		if (!this.linear) {
			return Number.POSITIVE_INFINITY;
		}

		const stepsArray = this.steps.toArray();
		for (let i = 0; i < stepsArray.length; ++i) {
			const step = stepsArray[i];
			if (step.formGroup?.invalid) {
				return i;
			}
		}
		return Number.POSITIVE_INFINITY;
	}

	ngAfterContentInit() {
		this.selectIndex();
	}

	private selectIndex(indexPossiblyStr: string | number = this._selectedIndex) {
		let index = typeof indexPossiblyStr === 'number' ? indexPossiblyStr : parseInt(indexPossiblyStr, 0);

		if (index > this.indexOfLastEnabledStep) {
			this._selectedIndex = this.indexOfLastEnabledStep;
			const currentForm = this.steps.toArray()[this.selectedIndex].formGroup;
			if (currentForm) {
				Object.values(currentForm.controls).forEach(control => control.markAsDirty());
			}
			return;
		}

		this._selectedIndex = index;
		if (!this.stepRefs) {
			return;
		}

		if (index >= this.stepRefs.length) {
			index = this.stepRefs.length - 1;
			this._selectedIndex = index;
		}
		if (index < 0) {
			index = 0;
			this._selectedIndex = index;
		}

		this.selectedIndexChange.emit(index);

		this.selectorLine.nativeElement.style.setProperty('--index', `${index}`);

		const elements = this.stepElements;
		if (elements.length === 0) {
			return;
		}

		for (let i = 0; i < index; ++i) {
			const element = elements[i];
			element.classList.add('payee-progress-bar-left');
			element.classList.remove('payee-progress-bar-right');
		}

		const selectedElement = elements[index];
		selectedElement.classList.remove('payee-progress-bar-left');
		selectedElement.classList.remove('payee-progress-bar-right');

		for (let i = index + 1; i < elements.length; ++i) {
			const element = elements[i];
			element.classList.remove('payee-progress-bar-left');
			element.classList.add('payee-progress-bar-right');
		}
	}
}
