import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'payee-datepicker',
	templateUrl: './payee-datepicker.component.html',
	styleUrls: ['./payee-datepicker.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PayeeDatepickerComponent),
		multi: true,
	}],
})
export class PayeeDatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
	@Input() placeholder = '';
	@Input() min: string | null = null;
	@Input() max: string | null = null;

	formControl: FormControl;
	get disabled() {
		return this.formControl.disabled;
	}

	private readonly destroy = new Subject<void>();

	constructor(
		private fb: FormBuilder,
	) {
		this.formControl = this.fb.control(null);
	}

	ngOnInit(): void {
		this.formControl.valueChanges
			.pipe(takeUntil(this.destroy))
			.subscribe({
				next: value => this.onChange(value),
			});
	}

	ngOnDestroy(): void {
		this.destroy.next();
		this.destroy.complete();
	}

	onCalendarIconClick(picker: any) {
		if (!this.disabled) {
			picker.open();
		}
	}

	// ControlValueAccessor

	onChange = (obj: any) => {};

	onTouched = () => {};

	registerOnChange(fn: (obj: any) => void): void { this.onChange = fn; }

	registerOnTouched(fn: () => void): void { this.onTouched = fn; }

	writeValue(obj: any): void {
		this.formControl.setValue(obj);
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.formControl.disable();
		} else {
			this.formControl.enable();
		}
	}
}
