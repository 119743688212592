import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import i18n from './translate';

export type HistoricCase = {
  state_label: string;
  stage_label: string;
  payee_case_reference_id: string;
  started_at: string | null;
};

export type ExpandedElementDetails = {
  payee_case: {
    id: string;
    payee_case_reference_id: string;
    description: string;
    legal_relationship_files: {
      file_name: string;
      storage_url: string;
    }[];
    country_iso: 'HU' | 'EN';
    legal_relationship_specification: string;
    remaining_amount: number;
    original_amount: number;
    displayed_claims_remaining_amount: number;
    displayed_claims_original_amount: number;
    hidden_claims_remaining_amount: number;
    hidden_claims_original_amount: number;
    currency: string;
    history: HistoricCase[];
  };
  debtor: {
    name: string;
    address: string | null;
    email: string | null;
    phone: string | null;
    representative: {
      name: string | null;
      type: string | null;
      address: string | null;
    };
  };
  claims: {
    due_date_at: string;
    remaining_amount: number;
    original_amount: number;
    currency: string;
    files: {
      file_name: string;
      storage_url: string;
    }[];
  }[];
  hiddenClaims: {
    remaining_amount: number;
    original_amount: number;
    label: string;
  }[];
};

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit {
  private static translationsLoaded = false;

  @Input() caseId: string;
  @Output() filterCase = new EventEmitter<string>();

  copyTooltipDisabled = true;
  expandedElementDetails: ExpandedElementDetails;
  isSpinnerEnabled = false;

  get showParent(): boolean {
    return this.expandedElementDetails.payee_case.history.length > 1;
  }

  get todayAsString(): string { return moment().format('YYYY.MM.DD'); }

  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  async ngOnInit() {
    if (!CaseDetailsComponent.translationsLoaded) {
      for (const [lang, translation] of Object.entries(i18n)) {
        this.translate.setTranslation(lang, translation, true);
      }
      CaseDetailsComponent.translationsLoaded = true;
    }

    setTimeout(() => this.isSpinnerEnabled = true, 250);

    try {
      const url = environment.baseUrl + `/api/case-details/${this.caseId}`;
      const result = await axios.get<ExpandedElementDetails>(url);

      this.expandedElementDetails = result.data;
    } catch (error) {
      this.snackbar.open(
        this.translate.instant('CASE_DETAILS.ERROR_MESSAGES.UNKNOWN_ERROR_WHILE_LOADING_DETAILS'),
        this.translate.instant('CASE_DETAILS.OK'),
        {
          duration: 5000,
        },
      );
      console.error('Error while loading case details', error);
    }
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
  }

  filterToHistoricCase(element: HistoricCase): void {
    this.filterCase.emit(element.payee_case_reference_id);
  }

  readonly environment = environment;
}
