import { Component } from '@angular/core';

@Component({
  selector: 'button[payee-back-button]',
  templateUrl: './payee-back-button.component.html',
  styleUrls: ['./payee-back-button.component.scss'],
  host: {
    color: 'white',
    class: 'payee-round-button',
  },
})
export class PayeeBackButtonComponent {}
