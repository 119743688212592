import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeSwitcherComponent } from './payee-switcher.component';
import { PayeeButtonsModule } from '../payee-buttons/payee-buttons.module';

@NgModule({
	declarations: [
		PayeeSwitcherComponent,
	],
	imports: [
		CommonModule,
		PayeeButtonsModule,
	],
	exports: [
		PayeeSwitcherComponent,
	],
})
export class PayeeSwitcherModule { }
