import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'payee-step',
	templateUrl: './payee-step.component.html',
	styleUrls: ['./payee-step.component.scss'],
})
export class PayeeStepComponent {
  @ContentChild('payeeStepLabel', { static: true }) stepLabelRef?: TemplateRef<any>;
  @Input() label?: string;

  @Input() formGroup?: FormGroup;
}
