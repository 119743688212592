import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartyType } from 'src/types';
import { ClientDataTableItem, ClientsService } from 'src/app/services/clients/clients.service';
import { EnumsService } from 'src/app/services/enums/enums.service';
import { partyType } from 'src/app/components/dashboard/enums/enum-descriptors';
import { Subject } from 'rxjs';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { CompanySearchAutocompleteItem, CompanySearchService } from 'src/app/shared/company-search-service/company-search.service';
import { AxiosError } from 'axios';
import { CountriesService, Country } from 'src/app/services/countries/countries.service';
import { takeUntil } from 'rxjs/operators';
import { extractIBAN } from 'ibantools';

@Component({
  selector: 'app-edit-client-dialog',
  templateUrl: './edit-client-dialog.component.html',
  styleUrls: ['./edit-client-dialog.component.scss']
})
export class EditClientDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;

  submitLoading = false;
  initLoading = false;

  private countries: Country[] = [];
  countryOptions: string[] = [];

  private partyTypes: PartyType[] = [];
  partyTypeOptions: number[] = [];

  private readonly destroy = new Subject<void>();

  type: 'ind' | 'org';
  get address(): FormGroup { return this.formGroup.get('address') as FormGroup; }
  get country(): FormControl { return this.address.get('country') as FormControl; }
  get settlement(): FormControl { return this.address.get('settlement') as FormControl; }
  get postcode(): FormControl { return this.address.get('postcode') as FormControl; }
  get street(): FormControl { return this.address.get('street') as FormControl; }
  get bankAccount(): FormGroup { return this.formGroup.get('bankAccount') as FormGroup; }
  get account_number(): FormControl { return this.bankAccount.get('account_number') as FormControl; }
  get iban(): FormControl { return this.bankAccount.get('iban') as FormControl; }
  get bic(): FormControl { return this.bankAccount.get('bic') as FormControl; }
  get bank_name(): FormControl { return this.bankAccount.get('bank_name') as FormControl; }
  get account_holder_name(): FormControl { return this.bankAccount.get('account_holder_name') as FormControl; }
  get tax_number(): FormControl { return this.formGroup.get('tax_number') as FormControl; }
  get name(): FormControl { return this.formGroup.get('name') as FormControl; }
  get party_type_id(): FormControl { return this.formGroup.get('party_type_id') as FormControl; }

  constructor(
    public dialogRef: MatDialogRef<EditClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private client: ClientDataTableItem,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private clientsService: ClientsService,
    private enumsService: EnumsService,
    private helpersService: HelpersService,
    private countriesService: CountriesService,
    private companySearchService: CompanySearchService,
  ) {
    this.formGroup = this.fb.group({
      tax_number: [this.client.tax_number],
      name: [this.client.name, Validators.required],
      party_type_id: [this.client.party_type_id, Validators.required],
      address: this.fb.group({
        country: [this.client.address.country, Validators.required],
        settlement: [this.client.address.settlement, Validators.required],
        postcode: [this.client.address.postcode, Validators.required],
        street: [this.client.address.street, Validators.required],
      }),
      bankAccount: this.fb.group({
        account_number: [this.client.bankAccount.account_number, this.helpersService.bankAccountValidator],
        iban: [this.client.bankAccount.iban, this.helpersService.bankAccountValidator],
        bic: [this.client.bankAccount.bic],
        bank_name: [this.client.bankAccount.bank_name],
        account_holder_name: [this.client.bankAccount.account_holder_name, Validators.required],
      })
    });
  }

  async ngOnInit(): Promise<void> {
    this.iban.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (value: string) => {
          try {
            const ibanResult = extractIBAN(value);
            if (ibanResult.valid) {
              this.account_number.patchValue(ibanResult.bban);
            }
          } catch (error) {
            console.error('Error while extracting iban', error);
          }
        },
      });
    try {
      this.initLoading = true;

      const [
        partyTypes,
        countries,
        detailedSearch,
      ] = await Promise.all([
        this.enumsService.getEnum<PartyType>(partyType.model),
        this.countriesService.getCountries(),
        this.companySearchService.detailedSearch(this.client.tax_number.slice(0, 8)),
      ]);

      this.partyTypes = partyTypes;
      this.partyTypeOptions = partyTypes.map(pt => pt.id);

      this.countries = countries.countries;
      this.countryOptions = this.countries.map(c => c.iso);

      if (detailedSearch?.company) {
        const company = detailedSearch.company;
        this.bankAccount.patchValue({
          account_number: company.bankAccountData.account_number,
          iban: company.bankAccountData.iban,
          bank_name: company.bankAccountData.bank_name,
          account_holder_name: company.bankAccountData.account_holder_name,
        });

        const country = this.countries.find(v => v.id === company.addressData.country_id);
        this.address.patchValue({
          country: country?.iso,
          settlement: company.addressData.settlement,
          postcode: company.addressData.postcode,
          street: company.addressData.street,
        });

        this.name.patchValue(company.basicData.name);
      }
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt a dialog megnyitásakor', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.initLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onCompanySelected(item: CompanySearchAutocompleteItem) {
    this.tax_number.patchValue(item.tax_number);
    this.party_type_id.patchValue(+item.party_type_id);
  }

  async submit() {
    if (this.formGroup.invalid || this.submitLoading) {
      return;
    }

    try {
      this.submitLoading = true;

      const result: ClientDataTableItem = await this.clientsService.updateClient(this.client.id, {
        name: this.name.value,
        address: {
          country_id: this.countries.find(c => this.country.value === c.iso)?.id as number,
          postcode: this.postcode.value,
          settlement: this.settlement.value,
          street: this.street.value
        },
        bankAccount: {
          account_holder_name: this.account_holder_name.value,
          account_number: this.account_number.value || undefined,
          bank_name: this.bank_name.value || undefined,
          bic: this.bic.value || undefined,
          iban: this.iban.value || undefined,
        },
        party_type_id: this.party_type_id.value,
        tax_number: this.tax_number.value || null,
      });
      Object.assign(this.client, result);

      this.dialogRef.close(this.name.value);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          this.helpersService.markAllChildrenAsDirty(this.formGroup);
          const errors: Record<string, string[]> = error.response.data.errors;
          Object.entries(errors).forEach(([key, values]) => {
            const formControl = this.formGroup.get(key);
            if (formControl) {
              formControl.setErrors({
                backend: values[0],
              });
            } else {
              this.formGroup.setErrors({
                backend: values[0],
              });
            }
          });
          return;
        }
      }
      console.error('Error while updating client', error);
      this.snackbar.open('Valami hiba történt mentéskor!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.submitLoading = false;
    }
  }

  readonly partyTypeDisplayWith = (id: number): string => {
    return this.partyTypes.find(t => t.id === id)?.label || '';
  };

  readonly countryDisplayWith = (iso: string): string => {
    return this.countries.find(t => t.iso === iso)?.name || '';
  };
}
