<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Melyik ingatlanokra menjen a végrehajtás?</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      Az email mellékletében látsz egy táblázatot / listát az adós
      ingatlanjairól.
      <b>
        Kérlek, írd le az alábbi mezőbe, hogy melyik ingatlanokra menjen a
        végrehajtás.
      </b>
      Ezt mi pedig továbbítjuk a végrehajtónak.
    </div>
  </ng-template>

  <form [formGroup]="realEstateForm">
    <payee-form-field
      [width]="'wider'"
      [height]="'taller'"
    >
      <textarea
        payee-input
        placeholder="Írd ide a saját szavaiddal..."
        formControlName="message"
      ></textarea>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>Elküldöm a végrehajtónak</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
