import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-header-app',
  templateUrl: './header-app.component.html',
  styleUrls: ['./header-app.component.scss']
})
export class HeaderAppComponent {
  @Input() set xClicked(fn: () => void) {
    this.onXClicked = fn;
  }
  @Input() xLink: string;
  @Input() payeeLink = 'https://payee.tech';

  @ContentChild('logoTemplate') logoTemplateRef: TemplateRef<any>;

  onXClicked = () => {};
}
