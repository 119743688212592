import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: '[payee-ripple]',
})
export class PayeeRippleDirective {
	@Input() @HostBinding('class.payee-ripple-unbound') payeeRippleUnbound = false;
	@Input() payeeRippleCentered = false;
	@Input() payeeRippleRadius?: string;
	@Input() payeeRippleDisabled = false;

	private trigger!: HTMLElement;
	@Input() set payeeRippleTrigger(trigger: HTMLElement) {
		this.trigger = trigger;

		trigger.addEventListener('mousedown', event => this.onMouseDown(event));
		trigger.addEventListener('mouseup', () => this.onMouseUp());
		trigger.addEventListener('mouseleave', () => this.onMouseUp());
	}

	private lastRippleExpired = false;
	private lastRipple?: HTMLElement;
	private isMouseDown = false;

	constructor(
		private ref: ElementRef<HTMLElement>,
		private renderer: Renderer2,
	) {
		this.payeeRippleTrigger = this.ref.nativeElement;
	}

	private onMouseDown(event: MouseEvent) {
		if (this.payeeRippleDisabled || event.currentTarget !== this.trigger) {
			return;
		}
		this.isMouseDown = true;
		this.addRipple(event);
	}

	private onMouseUp() {
		this.isMouseDown = false;
		this.removeLastRipple();
	}

	private getRippleWidth(event: MouseEvent) {
		if (this.payeeRippleRadius) {
			return parseInt(this.payeeRippleRadius);
		}
		const rect = this.trigger.getBoundingClientRect();
		if (this.payeeRippleCentered) {
			return rect.width / 2;
		}
		const x1 = event.clientX - rect.x;
		const x2 = rect.width + rect.x - event.clientX;
		return Math.max(x1, x2);
	}

	private getRippleHeight(event: MouseEvent) {
		if (this.payeeRippleRadius) {
			return parseInt(this.payeeRippleRadius);
		}
		const rect = this.trigger.getBoundingClientRect();
		if (this.payeeRippleCentered) {
			return rect.height / 2;
		}
		const y1 = event.clientY - rect.y;
		const y2 = rect.height + rect.y - event.clientY;
		return Math.max(y1, y2);
	}

	private getRippleLeft(event: MouseEvent) {
		const rect = this.trigger.getBoundingClientRect();
		if (this.payeeRippleCentered) {
			return rect.width / 2;
		}
		return event.clientX - rect.x;
	}

	private getRippleTop(event: MouseEvent) {
		const rect = this.trigger.getBoundingClientRect();
		if (this.payeeRippleCentered) {
			return rect.height / 2;
		}
		return event.clientY - rect.y;
	}

	private addRipple(event: MouseEvent) {
		const rippleWidth = this.getRippleWidth(event);
		const rippleHeight = this.getRippleHeight(event);
		const rippleRadius = Math.sqrt(rippleWidth * rippleWidth + rippleHeight * rippleHeight);

		const cursorFromLeft = this.getRippleLeft(event);
		const cursorFromTop = this.getRippleTop(event);

		const rippleElement = this.renderer.createElement('div') as HTMLDivElement;
		rippleElement.classList.add('payee-ripple-item');
		rippleElement.style.setProperty('--size', `${rippleRadius * 2}px`);
		rippleElement.style.left = `${cursorFromLeft - rippleRadius}px`;
		rippleElement.style.top = `${cursorFromTop - rippleRadius}px`;

		this.renderer.appendChild(this.ref.nativeElement, rippleElement);
		this.lastRipple = rippleElement;

		setTimeout(() => {
			if (this.isMouseDown && this.lastRipple === rippleElement) {
				this.lastRippleExpired = true;
				return;
			}
			this.renderer.removeChild(this.ref.nativeElement, rippleElement);
		}, 150);
	}

	private removeLastRipple() {
		if (this.lastRippleExpired && this.lastRipple) {
			this.renderer.removeChild(this.ref.nativeElement, this.lastRipple);
			this.lastRippleExpired = false;
			this.lastRipple = undefined;
		}
	}
}
