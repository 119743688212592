import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralLoadingDialogComponent } from '../../general-loading-dialog/general-loading-dialog.component';
import { GeneralSuccessDialogComponent } from '../../general-success-dialog/general-success-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingDialogService {
  private loadingDialog?: MatDialogRef<GeneralLoadingDialogComponent>;

  constructor(
    private dialog: MatDialog,
  ) { }

  isLoading() {
    return !!this.loadingDialog;
  }

  startLoading() {
    if (this.loadingDialog) {
      return;
    }
    this.loadingDialog = this.dialog.open(GeneralLoadingDialogComponent, {
      disableClose: true,
    });
  }

  stopLoading(result = true) {
    if (!this.loadingDialog) {
      return;
    }
    this.loadingDialog.close();
    this.loadingDialog = undefined;

    if (result) {
      this.dialog.open(GeneralSuccessDialogComponent);
    }
  }
}
