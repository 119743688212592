<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Sikeresen jelezted a mulasztást!</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <span class="description">
      Ha az adósod nem fizette meg időben a részletfizetési terv szerinti
      részletet, akkor a jogi eljárás folytatását kérheted.
    </span>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>Jogi behajtás folytatása</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      [mat-dialog-close]="undefined"
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
