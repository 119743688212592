<payee-popup
  class="edit-case-popup"
  title="Ügy másolása"
  description="Az ügyet le tudod másolni, bármelyik termékké alakítva"
>
  <payee-info width="wide">
    Az új ügy nem fog partner-hez tartozni! Pl. számlázz-os ügyeknél nem lesz szinkronizálva számlázz.hu-val!
  </payee-info>
  <payee-form-field width="wide">
    <span payee-label>Termék</span>
    <payee-autocomplete
      placeholder="Válassz terméket"
      [formControl]="productControl"
      [options]="productOptions"
      [displayWith]="productDisplayWith"
    ></payee-autocomplete>
  </payee-form-field>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      (click)="submit()"
      [disabled]="loading"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Bezárás
    </button>
  </ng-template>
</payee-popup>
