<payee-header></payee-header>
<app-form-template [title]="title" [submit]="login" [formGroup]="loginForm">
  <payee-form-field width="wide">
    <span payee-label>Email cím</span>
    <input payee-input formControlName="email" />
    <ng-container payee-error *ngIf="email.dirty">
      <mat-error *ngIf="email.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
      <mat-error *ngIf="email.hasError('email')">
        Létező email címet adjon meg
      </mat-error>
    </ng-container>
  </payee-form-field>

  <payee-form-field width="wide">
    <span payee-label>Jelszó</span>
    <input type="password" payee-input formControlName="password" />
    <ng-container payee-error *ngIf="password.dirty">
      <mat-error *ngIf="password.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
    </ng-container>
  </payee-form-field>

  <mat-checkbox
    *ngIf="hasRememberMeButton"
    color="primary"
    class="remember-me"
    payee-input
    formControlName="remember_me"
  >
    Emlékezz rám
  </mat-checkbox>

  <input type="submit" hidden />

  <mat-error *ngIf="loginForm.hasError('unknown')">
    Valami hiba történt
  </mat-error>
  <mat-error *ngIf="loginForm.hasError('invalid_password')">
    Helytelen email cím, vagy jelszó
  </mat-error>

  <button
    payee-button
    [disabled]="loading"
    type="submit"
    width="wide"
    class="login-button"
    [isRoundButton]="false"
    (click)="login()"
  >
    Belépés
  </button>

  <ng-content></ng-content>
</app-form-template>
