<input
	[ngClass]="disabled ? 'cursor-default' : 'cursor-pointer'"
	(click)="onCalendarIconClick(picker)"
	readonly
	payee-input
	[matDatepicker]="picker"
	[formControl]="formControl"
	[placeholder]="placeholder"
	[min]="min"
	[max]="max"
>
<button
	payee-suffix
	payee-icon-button
	color="custom"
>
	<payee-icon *ngIf="!disabled" name="calendar" width="24" height="24"></payee-icon>
	<payee-icon *ngIf="disabled" name="disabled-calendar" width="24" height="24"></payee-icon>
</button>
<mat-datepicker #picker></mat-datepicker>
