<main>
  <h1>Kampány létrehozása</h1>
  <form
    id="create-campaign-form"
    (submit)="createCampaign()"
    [formGroup]="createForm"
  >
    <div class="flex" id="create-campaign-form-fields">
      <payee-form-field>
        <input type="text" payee-input formControlName="utm_content" />
        <span payee-label>utm_content</span>
        <ng-container payee-error *ngIf="utm_content.invalid && utm_content.dirty">
          <mat-error *ngIf="utm_content.hasError('required')">
            A mező kitöltése kötelező
          </mat-error>
          <mat-error *ngIf="utm_content.hasError('maxlength')">
            A kampány nem lehet hosszabb
            {{ utm_content.errors?.maxlength.requiredLength }}
            karakternél!
          </mat-error>
        </ng-container>
      </payee-form-field>
    </div>
    <mat-error *ngIf="createForm.hasError('unknown')">{{ createForm.errors?.unknown }}</mat-error>
    <button payee-button type="submit">Kampány létrehozása</button>
  </form>
  <table
    mat-table
    matSort
    matSortDirection="asc"
    matSortDisableClear
    matSortActive="created_at"
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="campaign-table"
  >
    <ng-container matColumnDef="utm_content">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>utm_content</th>
      <td mat-cell *matCellDef="let element">{{ element.utm_content }}</td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Kampány létrejötte
      </th>
      <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
    </ng-container>

    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a payee-button [routerLink]="'/admin/campaign-user/' + element.utm_content">Felhasználók</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <router-outlet></router-outlet>
</main>
