import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

export type DialogData = {
  transition_label: string;
};

@Component({
  selector: 'app-no-attachment-no-deadline-dialog',
  templateUrl: './no-attachment-no-deadline-dialog.component.html',
  styleUrls: ['./no-attachment-no-deadline-dialog.component.scss']
})
export class NoAttachmentNoDeadlineDialogComponent implements OnInit {
  dialogData?: DialogData;

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private dialogRef: MatDialogRef<NoAttachmentNoDeadlineDialogComponent>,
    private workflowService: WorkflowService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      message: [''],
    });
  }

  async ngOnInit() {
    this.dialogData = await this.workflowService.getDialogData({
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    }) as DialogData;
  }

  readonly onSubmit = async () => {
    if (this.form.invalid) {
      return;
    }

    const closeValue: RunTransition = {
      transitionId: this.data.transitionId,
      caseId: this.data.caseId,
      params: {
        message: this.form.value.message,
      },
    };

    this.dialogRef.close(closeValue);
  };

  readonly onCancel = () => {
    this.dialogRef.close();
  };
}
