import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss']
})
export class FormTemplateComponent {
  @Input() submit: () => void | Promise<void>;
  @Input() formGroup?: FormGroup;

  @ContentChild('titleTemplate', { static: false }) titleTemplateRef: TemplateRef<any>;
  @Input() title?: string;

  constructor(
    private fb: FormBuilder,
  ) {
    // We initialize formGroup to avoid errors if it's not provided
    this.formGroup = this.fb.group({});
  }

  onSubmit() {
    if (this.submit) {
      this.submit();
    }
  }
}
