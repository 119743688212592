import { Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { PayeeButtonComponent } from '../payee-button/payee-button.component';

@Component({
	selector: 'button[payee-big-switcher-button]',
	templateUrl: './payee-big-switcher-button.component.html',
	styleUrls: ['./payee-big-switcher-button.component.scss'],
})
export class PayeeBigSwitcherButtonComponent extends PayeeButtonComponent {
	private static instances = 0;
  @Input() value: any;
  @HostBinding('attr.selected') selected?: boolean;

  @Input() title?: string;
  @Input() description?: string;

  @ContentChild('payeeBigSwitcherContent') contentTemplateRef?: TemplateRef<any>;

  readonly id = PayeeBigSwitcherButtonComponent.instances++;
}
