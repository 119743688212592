import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'payee-info',
	templateUrl: './payee-info.component.html',
	styleUrls: ['./payee-info.component.scss'],
})
export class PayeeInfoComponent {
  @Input() @HostBinding('attr.width') width?: string;
  @Input() @HostBinding('attr.height') height?: string;
}
