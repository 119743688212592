import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PayeeRadioChange } from '../payee-radio-change';

@Component({
	selector: 'payee-radio-button',
	templateUrl: './payee-radio-button.component.html',
	styleUrls: ['./payee-radio-button.component.scss'],
})
export class PayeeRadioButtonComponent {
	private static instances = 0;
	@Input() labelPosition: 'before' | 'after' = 'after';
	@Input() id = PayeeRadioButtonComponent.instances++;
	@Input() value?: any;
	@Input() checked? = false;
	@Input() disabled = false;

	@Output() change = new EventEmitter<PayeeRadioChange>();

	onSelect(checked = true, emitChanges = true) {
		if (this.disabled) {
			return;
		}

		this.checked = checked;

		if (emitChanges) {
			this.emitChange();
		}
	}

	private emitChange() {
		this.change.emit({
			source: this,
			value: this.value,
		});
	}
}
