import { Component, Input } from '@angular/core';

@Component({
	selector: 'payee-accordion',
	templateUrl: './payee-accordion.component.html',
	styleUrls: ['./payee-accordion.component.scss'],
})
export class PayeeAccordionComponent {
	@Input() headline?: string;
	@Input() open = false;

	onClick() {
		this.open = !this.open;
	}
}
