import { Component } from '@angular/core';

@Component({
  selector: 'app-discard-case',
  templateUrl: './discard-case.component.html',
  styleUrls: ['./discard-case.component.scss']
})
export class DiscardCaseComponent {

  constructor() { }

}
