import { Component, forwardRef, HostBinding, Injector, Input, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormControlDirective,
	FormControlName,
	FormGroupDirective,
	NgControl,
	NgModel,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
	selector: 'payee-password-form-field',
	templateUrl: './payee-password-form-field.component.html',
	styleUrls: ['./payee-password-form-field.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PayeePasswordFormFieldComponent),
		multi: true,
	}],
})
export class PayeePasswordFormFieldComponent implements OnInit, ControlValueAccessor {
	@Input() width?: string;
	@Input() height?: string;
	@Input() placeholder = '';

	showPassword = false;
	formControl: FormControl;

	constructor(
		private fb: FormBuilder,
		private inj: Injector,
	) {
		this.formControl = this.fb.control('');
	}

	ngOnInit(): void {
		const ngControl = this.inj.get(NgControl, null);

		if (!ngControl) {
			return;
		}

		switch (ngControl.constructor) {
			case NgModel: {
				this.formControl = ngControl.control as FormControl;
				break;
			}
			case FormControlName: {
				this.formControl = this.inj.get(FormGroupDirective).getControl(ngControl as FormControlName);
				break;
			}
			default: {
				this.formControl = (ngControl as FormControlDirective).form as FormControl;
				break;
			}
		}
	}

	// ControlValueAccessor

	onChange = (obj: any) => { };

	onTouched = () => { };

	registerOnChange(fn: (obj: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: any): void {
		this.onChange(obj);
	}
}
