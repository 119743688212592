import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

export interface ExecuteEventDialogComponentData {
  transitionId: string;
  caseId: string;
}

@Component({
  selector: 'app-execute-event-dialog',
  templateUrl: './execute-event-dialog.component.html',
  styleUrls: ['./execute-event-dialog.component.scss']
})
export class ExecuteEventDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ExecuteEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RunTransition,
  ) { }

  submit(): void {
    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    };

    this.dialogRef.close(closeValue);
  }
}
