import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { FullPaymentWarningComponent } from '../full-payment-warning/full-payment-warning.component';

export type DialogData = {
  transition_ids: {
    full_payment: string,
    partial_payment: string,
  },
  payee_case: {
    remaining_amount: number,
    currency: string,
  },
  claims: {
    is_paid: boolean;
    remaining_amount: number,
    claim_type_label: string,
    optional_claims: {
      remaining_amount: number,
      claim_type_label: string,
    }[];
  }[];
};

export type TransitionParam = {
  amount: number;
};

type PaymentAmount = {
  amount: number;
  optional_claims: {
    amount: number;
  }[];
};

@Component({
  selector: 'app-full-payment-warning',
  templateUrl: './manual-payment.component.html',
  styleUrls: ['./manual-payment.component.scss']
})
export class ManualPaymentComponent implements OnInit, OnDestroy {
  dialogData!: DialogData;
  initLoading = false;

  readonly manualPaymentForm: FormGroup;
  private readonly destory = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ManualPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
    private snackbar: MatSnackBar,
  ) {
    this.manualPaymentForm = this.fb.group({
      paymentAmount: '',
    });
  }

  get isFullPayment(): boolean {
    const remainingAmount = this.dialogData?.payee_case?.remaining_amount ?? Infinity;
    const providedAmount = this.getNumberValueOfString(this.paymentAmount.value);

    return providedAmount >= remainingAmount;
  }
  get paymentAmount(): FormControl { return this.manualPaymentForm.get('paymentAmount') as FormControl; }

  async ngOnInit(): Promise<void> {
    try {
      this.initLoading = true;
      this.dialogData = await this.workflowService.getDialogData({
        caseId: this.data.caseId,
        transitionId: this.data.transitionId,
      }) as DialogData;

    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt az adatok betöltésekor!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
      return;
    } finally {
      this.initLoading = false;
    }

    this.paymentAmount.setValidators([
      Validators.required,
      this.minValidator(1),
      this.maxValidator(this.dialogData.payee_case.remaining_amount),
    ]);

    this.paymentAmount.valueChanges
      .pipe(
        takeUntil(this.destory),
        map(v => this.getNumberValueOfString(v).toLocaleString('hu-HU').replace(/^0*/, '')),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (value: string) => {
          this.paymentAmount.patchValue(value, { emitEvent: false });
        },
      });

    if (this.dialogData!.transition_ids.full_payment === this.data.transitionId) {
      this.paymentAmount.patchValue(this.dialogData!.payee_case.remaining_amount);
    }
  }

  ngOnDestroy(): void {
    this.destory.next();
    this.destory.complete();
  }

  readonly submit = async () => {
    if (this.manualPaymentForm.invalid || !this.dialogData) {
      Object.values(this.manualPaymentForm.controls).forEach(control => control.markAsDirty());
      return;
    }

    if (this.isFullPayment) {
      const confirmDialogRef = this.dialog.open(FullPaymentWarningComponent);
      const result = await confirmDialogRef.afterClosed().toPromise();
      if (!result) {
        return;
      }
    }

    const transitionId = this.isFullPayment ?
      this.dialogData.transition_ids.full_payment
      : this.dialogData.transition_ids.partial_payment;

    const params = this.getParams();

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId,
      params,
    };
    this.dialogRef.close(closeValue);
  };

  private getNumberValueOfString(str: string): number {
    if (typeof str === 'number') {
      return str;
    } else if (typeof str === 'string') {
      return parseInt(str.replace(/\D/g, '').slice(0, 8) || '0');
    } else {
      console.warn('Invalid number value', {
        type: typeof str,
      });
      return 0;
    }
  }

  private readonly getParams = (): TransitionParam => {
    const paymentAmount = this.getNumberValueOfString(this.paymentAmount.value);
    return {
      amount: paymentAmount,
    };
  };

  private minValidator(value: number) {
    return (control: AbstractControl) => {
      if (control.value === '') {
        return null;
      }

      const num = this.getNumberValueOfString(control.value);
      if (num < value) {
        return {
          min: {
            expected: value,
            actual: num,
          },
        };
      } else {
        return null;
      }
    };
  }

  private maxValidator(value: number) {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const num = this.getNumberValueOfString(control.value);
      if (num > value) {
        return {
          max: {
            expected: value,
            actual: num,
          },
        };
      } else {
        return null;
      }
    };
  }
}
