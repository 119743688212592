import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AxiosError } from 'axios';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.scss']
})
export class LoginTemplateComponent {
  @Input() title: string = 'Belépés';
  @Input() hasRememberMeButton: boolean = true;

  loginForm: FormGroup;
  hidePassword = true;
  loading = false;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember_me: false,
    });
    this.titleService.setTitle('Bejelentkezés - Payee');

    if (this.authService.isAuthenticated) {
      this.router.navigateByUrl(environment.afterLoginPath);
    }
  }

  get email(): FormControl { return this.loginForm.get('email') as FormControl; }
  get password(): FormControl { return this.loginForm.get('password') as FormControl; }
  get remember_me(): FormControl { return this.loginForm.get('remember_me') as FormControl; }

  readonly login = async () => {
    if (this.loginForm.invalid || this.loading) {
      return;
    }
    try {
      this.loading = true;

      if (this.remember_me.value) {
        this.authService.setPersistance('local');
      } else {
        this.authService.setPersistance('session');
      }

      await this.authService.login(this.email.value, this.password.value);
    } catch (e) {
      if (e instanceof AxiosError) {
        const message: string | undefined = e.response?.data?.message;
        if (
          message === 'The user credentials were incorrect.'
          || message === 'INVALID_PASSWORD'
        ) {
          this.loginForm.setErrors({
            invalid_password: true,
          });
          return;
        }
      }

      console.error('Unknown error', e);
      this.snackBar.open(
        'Valami hiba történt, próbáld újra késöbb!',
        'Ok',
        {
          duration: 10000,
        },
      );
    } finally {
      this.loading = false;
    }
  };
}
