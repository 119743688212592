<input
	payee-input
	[formControl]="searchControl"
	[matAutocomplete]="auto"
	[placeholder]="placeholder"
>
<mat-autocomplete
	#auto="matAutocomplete"
	(optionSelected)="optionSelected($event)"
>
	<mat-option
		*ngFor="let option of this.filteredOptions"
		[value]="option"
	>
		{{displayWith(option)}}
	</mat-option>
</mat-autocomplete>