import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AirtableEmailTestComponent } from './components/dashboard/airtable-email-test/airtable-email-test.component';
import { CasesListComponent } from './components/dashboard/cases-list/cases-list.component';
import { ClientsListComponent } from './components/dashboard/clients-list/clients-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EnumsComponent } from './components/dashboard/enums/enums.component';
import { ProductDiscountsComponent } from './components/dashboard/product-discounts/product-discounts.component';
import { ProductPricesComponent } from './components/dashboard/product-prices/product-prices.component';
import { RolesComponent } from './components/dashboard/roles/roles.component';
import { WorkflowEmailTestComponent } from './components/dashboard/workflow-email-test/workflow-email-test.component';
import { LoginComponent } from './components/login/login/login.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { GetPasswordLinkComponent } from './components/forgot-password/get-password-link/get-password-link.component';
import { GetPasswordLinkSuccessComponent } from './components/forgot-password/get-password-link-success/get-password-link-success.component';
import { NewPasswordComponent } from './components/forgot-password/new-password/new-password.component';
import { NewPasswordSuccessComponent } from './components/forgot-password/new-password-success/new-password-success.component';
import { CaseStartsComponent } from './components/dashboard/metrics/case-starts/case-starts.component';
import { MetricsComponent } from './components/dashboard/metrics/metrics.component';
import { SoftPaidComponent } from './components/dashboard/metrics/soft-paid/soft-paid.component';
import { PartnersListComponent } from './components/dashboard/partners-list/partners-list.component';
import { SzamlazzhuComponent } from './components/dashboard/szamlazzhu/szamlazzhu.component';
import { InvoicesTableComponent } from './components/dashboard/szamlazzhu/invoices-table/invoices-table.component';
import { InvoiceFormTableComponent } from './components/dashboard/szamlazzhu/invoice-form-table/invoice-form-table.component';
import { KsziInfoComponent } from './components/dashboard/szamlazzhu/kszi-info/kszi-info.component';
import { InvoiceResendComponent } from './components/dashboard/szamlazzhu/invoice-resend/invoice-resend.component';
import { FailedJobsComponent } from './components/dashboard/szamlazzhu/failed-jobs/failed-jobs.component';
import { CampaignsComponent } from './components/dashboard/campaigns/campaigns.component';
import { CampaignUserTableComponent } from './components/dashboard/campaigns/campaign-user-table/campaign-user-table.component';

const routes: Routes = [
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'campaigns',
        component: CampaignsComponent,
      },
      {
        path: 'campaign-user/:campaign',
        component: CampaignUserTableComponent,
      },
      {
        path: 'metrics',
        component: MetricsComponent,
        children: [
          { path: 'case-starts', component: CaseStartsComponent },
          { path: 'soft-paid', component: SoftPaidComponent },
          { path: '**', redirectTo: 'case-starts' },
        ],
      },
      { path: 'airtable-email-test', component: AirtableEmailTestComponent },
      { path: 'workflow-email-test', component: WorkflowEmailTestComponent },
      // {
      //   path: 'users-list',
      //   component: UsersListComponent,
      // },
      {
        path: 'clients-list',
        component: ClientsListComponent,
      },
      {
        path: 'cases-list',
        component: CasesListComponent,
      },
      {
        path: 'enums',
        component: EnumsComponent,
      },
      {
        path: 'szamlazzhu',
        component: SzamlazzhuComponent,
        children: [
          {
            path: 'invoices',
            component: InvoicesTableComponent,
          },
          {
            path: 'invoice-form',
            component: InvoiceFormTableComponent,
          },
          {
            path: 'kszi-info',
            component: KsziInfoComponent,
          },
          {
            path: 'invoice-resend',
            component: InvoiceResendComponent,
          },
          {
            path: 'kszi-info',
            component: KsziInfoComponent,
          },
          {
            path: 'failed-jobs',
            component: FailedJobsComponent,
          },
          {
            path: '**',
            redirectTo: 'invoices',
          },
        ],
      },
      {
        path: 'partners',
        component: PartnersListComponent,
      },
      {
        path: 'roles',
        component: RolesComponent,
      },
      {
        path: 'product-prices',
        component: ProductPricesComponent,
      },
      {
        path: 'product-discounts',
        component: ProductDiscountsComponent,
      },
      { path: '**', redirectTo: 'cases-list' },
    ],
  },
  { path: 'forgot-password-get-link', component: GetPasswordLinkComponent },
  { path: 'forgot-password-get-link-success', component: GetPasswordLinkSuccessComponent },
  { path: 'forgot-password-new-password/:token', component: NewPasswordComponent },
  { path: 'forgot-password-new-password-success', component: NewPasswordSuccessComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
