<div class="wrapper flex column" [class.close]="!open">
  <div class="logo-box flex hcenter vcenter">
    <img
      *ngIf="open"
      class="logo"
      src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg"
      width="100px"
      routerLink="dashboard"
    />
    <img
      *ngIf="!open"
      class="logo"
      src="assets/shared/global-images/payee-logos/v1/payee-webclip.svg"
      width="56.83px"
      routerLink="dashboard"
    />
  </div>

  <div
    *ngIf="showHiddenPages"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="product-prices"
    [matTooltip]="open ? '' : 'TERMÉKEK'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">TERMÉKEK</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="campaigns"
    [matTooltip]="open ? '' : 'KAMPÁNYOK'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">KAMPÁNYOK</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="metrics"
    [matTooltip]="open ? '' : 'KIMUTATÁSOK'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">KIMUTATÁSOK</span>
  </div>
  <div
    *ngIf="showHiddenPages"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="product-discounts"
    [matTooltip]="open ? '' : 'KEDVEZMÉNYEK'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">KEDVEZMÉNYEK</span>
  </div>
  <div
    *ngIf="showHiddenPages"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="airtable-email-test"
    [matTooltip]="open ? '' : 'AIRTABLE EMAIL TESZT'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">AIRTABLE EMAIL TESZT</span>
  </div>
  <div
    *ngIf="showHiddenPages"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="workflow-email-test"
    [matTooltip]="open ? '' : 'WORKFLOW EMAIL TESZT'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">WORKFLOW EMAIL TESZT</span>
  </div>
  <div
    *ngIf="showHiddenPages"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="enums"
    [matTooltip]="open ? '' : 'ENUMS'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">ENUMS</span>
  </div>
  <div
    *ngIf="showHiddenPages"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="partners"
    [matTooltip]="open ? '' : 'PARTNERS'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">PARTNERS</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="szamlazzhu"
    [matTooltip]="open ? '' : 'SZAMLAZZHU'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">SZAMLAZZHU</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="cases-list"
    [matTooltip]="open ? '' : 'ÖSSZES ÜGY'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">ÖSSZES ÜGY</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="clients-list"
    [matTooltip]="open ? '' : 'HITELEZŐK'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">HITELEZŐK</span>
  </div>
  <!-- <div class="menu-button flex vcenter" routerLinkActive="active" routerLink="workflow-init"
    [matTooltip]="open ? '' : 'WORKFLOW INIT'" matTooltipPosition="right" matTooltipClass="sidebar-tooltip">
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">WORKFLOW INIT</span>
  </div> -->
  <!-- <div class="menu-button flex vcenter" routerLinkActive="active" routerLink="users-list"
    [matTooltip]="open ? '' : 'USERS'" matTooltipPosition="right" matTooltipClass="sidebar-tooltip">
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">USERS</span>
  </div> -->
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="roles"
    [matTooltip]="open ? '' : 'JOGOSULTSÁGOK'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">JOGOSULTSÁGOK</span>
  </div>
  <div class="logout-divider"></div>
  <div
    class="menu-button flex vcenter"
    (click)="logout()"
    [matTooltip]="open ? '' : 'KIJELENTKEZÉS'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="logout-red"></payee-icon>
    <span [class.close]="!open" color="alert-red">Kijelentkezés</span>
  </div>
  <div class="grow3"></div>
  <!--<div [class.close]="!open" class="payee-email">hello@payee.tech</div>-->
  <div [class.close]="!open" class="version">
    Verziószám: <strong #appVersion>v{{ versionNumber }}</strong>
  </div>
  <button
    [class.close]="!open"
    mat-button
    class="toggle-button"
    (click)="toggleOpen()"
  >
    <img
      *ngIf="open"
      src="assets/shared/global-images/icons/arrow-double-left-grey.svg"
    />
    <img
      *ngIf="!open"
      src="assets/shared/global-images/icons/arrow-double-right-grey.svg"
    />
  </button>
</div>
