import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { GeneralLoadingDialogComponent } from "./general-loading-dialog.component";
import { PayeePopupModule } from "src/app/shared/payee-popup/payee-popup.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [GeneralLoadingDialogComponent],
  imports: [
    PayeePopupModule,
    MatDialogModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GeneralLoadingDialogModule { }