<payee-form-field [width]="width" [height]="height">
  <ng-content payee-label select="[payee-label]"></ng-content>
  <input
    payee-input [formControl]="formControl"
    [type]="showPassword ? 'text' : 'password'"
    [placeholder]="placeholder"
  >
  <ng-content error-icon select="[error-icon]"></ng-content>
  <button
    type="button" payee-suffix payee-icon-button *ngIf="!showPassword"
    (click)="showPassword = true" style="pointer-events: all;" color="white"
  >
    <img src="assets/shared/global-images/icon-set/eyes-on.svg">
  </button>
  <button
    type="button" payee-suffix payee-icon-button *ngIf="showPassword"
    (click)="showPassword = false" style="pointer-events: all;" color="white"
  >
    <img src="assets/shared/global-images/icon-set/eyes-off.svg">
  </button>
  <ng-content payee-error select="[payee-error]"></ng-content>
</payee-form-field>