import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type Country = {
  id: number;
  iso: string;
  name: string;
};

export type GetCountriesResponse = {
  countries: Country[];
};

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  async getCountries(): Promise<GetCountriesResponse> {
    const url = `${environment.baseUrl}/api/country`;
    const result = await axios.get<GetCountriesResponse>(url);
    return result.data;
  }
}
