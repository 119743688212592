import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeBigSwitcherComponent } from './payee-big-switcher.component';

@NgModule({
	declarations: [
		PayeeBigSwitcherComponent,
	],
	imports: [
		CommonModule,
	],
	exports: [
		PayeeBigSwitcherComponent,
		CommonModule,
	],
})
export class PayeeBigSwitcherModule { }
