import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeDropzoneComponent } from './payee-dropzone.component';
import { PayeeDropzoneDirective } from './payee-dropzone.directive';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';

@NgModule({
	declarations: [
		PayeeDropzoneComponent,
		PayeeDropzoneDirective,
	],
	imports: [
		CommonModule,
		PayeeIconModule,
	],
	exports: [
		PayeeDropzoneComponent,
		PayeeDropzoneDirective,
	],
})
export class PayeeDropzoneModule { }
