export default {
  CASE_DETAILS: {
    OK: 'OK',
    CASE_ID: 'Case ID:',
    COPIED: 'Copied',
    DEBTOR_DATA: {
      TITLE: 'Customer',
      NAME_LABEL: 'Customer name',
      ADDRESS_LABEL: 'Customer address',
      EMAIL_LABEL: 'Customer email address',
      PHONE_LABEL: 'Customer phone no.',
    },
    LEGAL: {
      TITLE: 'Proof of legal relationship',
      FILES_LABEL: 'Uploaded files',
      DESCRIPTION_LABEL: 'Description',
    },
    CLAIMS: {
      TITLE: 'Claims',
      TOTAL_LABEL: 'Sum of claims',
      CAPITAL_CLAIMS: {
        TITLE: 'Principal claims:',
        INDEX: '{{index}}. claim',
        DUE_DATE_LABEL: 'Due date:',
        FILE_TITLE: 'Uploaded:',
        TOTAL_LABEL: 'Sum of principal claims',
      },
      OPTIONAL_CLAIMS: {
        TITLE: 'Related claims:',
        TOTAL_LABEL: 'Sum of related claims',
      },
    },
    HISTORY: {
      TITLE: 'Case history',
      STARTED_AT: 'Started at:',
    },
    ERROR_MESSAGES: {
      UNKNOWN_ERROR_WHILE_LOADING_DETAILS: 'Error while loading the events! Please try again.'
    }
  },
};