import { Directive, ElementRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';

@Directive({
	selector: 'input[payee-input], textarea[payee-input]',
})
export class PayeeInputDirective implements OnInit {
	@Input() formControl?: FormControl;
	@Input() formControlName?: string;

	readonly element: HTMLElement;

	constructor(
		private ref: ElementRef<HTMLElement>,
		@Optional() @Host() @SkipSelf()
		private controlContainer: ControlContainer,
	) {
		this.element = this.ref.nativeElement;
	}

	ngOnInit(): void {
		if (this.controlContainer) {
			if (this.formControlName) {
				const control = this.controlContainer.control?.get(this.formControlName);
				if (control) {
					this.formControl = control as FormControl;
				}
			}
		}
	}
}
