import { Component, forwardRef, Input } from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
	selector: 'payee-copy-form-field',
	templateUrl: './payee-copy-form-field.component.html',
	styleUrls: ['./payee-copy-form-field.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PayeeCopyFormFieldComponent),
		multi: true,
	}],
})
export class PayeeCopyFormFieldComponent implements ControlValueAccessor {
	@Input() placeholder = '';

	value: any;

	// ControlValueAccessor

	onChange = (obj: any) => {};

	onTouched = () => {};

	registerOnChange(fn: (obj: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: any): void {
		this.value = obj;
	}

	change(value: any) {
		this.onChange(value);
	}
}
