import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

export type DialogData = {
  states: Record<string, {
    id: string;
    label: string;
  }>;
};

@Component({
  selector: 'app-overwrite-state-dialog',
  templateUrl: './overwrite-state-dialog.component.html',
  styleUrls: ['./overwrite-state-dialog.component.scss']
})
export class OverwriteStateDialogComponent implements OnInit {
  dialogData?: DialogData;

  stateForm: FormControl;
  stateOptions: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<OverwriteStateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RunTransition,
    private workflowService: WorkflowService,
    private fb: FormBuilder,
  ) {
    this.stateForm = this.fb.control(null, [Validators.required]);
  }

  async ngOnInit() {
    this.dialogData = await this.workflowService.getDialogData({
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    }) as DialogData;

    this.stateOptions = Object.keys(this.dialogData.states);
  }

  submit(): void {
    if (this.stateForm.invalid) {
      this.stateForm.markAsDirty();
      return;
    }
    
    const state = this.dialogData?.states[this.stateForm.value];
    if (!state) {
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: {
        to_state: state.id,
      },
    };

    this.dialogRef.close(closeValue);
  }

  readonly stateDisplayWith = (s?: keyof DialogData['states']): string => {
    if (!s || !this.dialogData) {
      return '';
    }

    return this.dialogData.states[s].label;
  }
}
