import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeBigSwitcherButtonComponent } from './payee-big-switcher-button/payee-big-switcher-button.component';
import { PayeeButtonComponent } from './payee-button/payee-button.component';
import { PayeeCopyButtonComponent } from './payee-copy-button/payee-copy-button.component';
import { PayeeIconButtonComponent } from './payee-icon-button/payee-icon-button.component';
import { PayeeMiniIconButtonComponent } from './payee-mini-icon-button/payee-mini-icon-button.component';
import { PayeeSwitcherButtonComponent } from './payee-switcher-button/payee-switcher-button.component';
import { PayeeRippleModule } from '../payee-ripple/payee-ripple.module';
import { PayeeBackButtonComponent } from './payee-back-button/payee-back-button.component';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    PayeeBigSwitcherButtonComponent,
    PayeeButtonComponent,
    PayeeCopyButtonComponent,
    PayeeIconButtonComponent,
    PayeeMiniIconButtonComponent,
    PayeeSwitcherButtonComponent,
    PayeeBackButtonComponent,
  ],
  imports: [
    CommonModule,
    PayeeRippleModule,
    PayeeIconModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    PayeeBigSwitcherButtonComponent,
    PayeeButtonComponent,
    PayeeCopyButtonComponent,
    PayeeIconButtonComponent,
    PayeeMiniIconButtonComponent,
    PayeeSwitcherButtonComponent,
    PayeeBackButtonComponent,
  ],
})
export class PayeeButtonsModule { }
