<app-header-app xLink="/login"></app-header-app>
<app-form-template title="Új jelszó beállítása" [formGroup]="passwordForm" (submit)="setNewPassword()">
  <payee-password-form-field width="wide" formControlName="password">
    <span payee-label>Új jelszó</span>
    <ng-container payee-error *ngIf="password.dirty">
      <mat-error *ngIf="password.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
      <mat-error *ngIf="password.hasError('minlength')">
        A jelszónak legalább 6 karakter hosszúnak kell lennie!
      </mat-error>
    </ng-container>
  </payee-password-form-field>

  <payee-password-form-field width="wide" formControlName="passwordConfirmation">
    <span payee-label>Új jelszó megerősítése</span>
    <ng-container payee-error *ngIf="passwordConfirmation.dirty">
      <mat-error *ngIf="passwordConfirmation.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
      <mat-error *ngIf="passwordConfirmation.hasError('passwordsDontMatch')">
        A jelszavak nem egyeznek
      </mat-error>
    </ng-container>
  </payee-password-form-field>

  <button payee-button width="wide" [disabled]=loading>
    <ng-container *ngIf="!loading">
      Beállítás és mentés
    </ng-container>
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
</app-form-template>