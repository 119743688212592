<main>
  <table
    mat-table
    matSort
    matSortDirection="asc"
    matSortDisableClear
    matSortActive="created_at"
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="campaign-table"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Felhasználó neve</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Felhasználó emailje</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="payee_cases_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Ügyek száma
      </th>
      <td mat-cell *matCellDef="let element">{{ element.payee_cases_count }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <router-outlet></router-outlet>
</main>
