import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'payee-header',
	templateUrl: './payee-header.component.html',
	styleUrls: ['./payee-header.component.scss'],
})
export class PayeeHeaderComponent {
  @ContentChild('payeeHeaderLogoTemplate') headerLogoTemplateRef?: TemplateRef<any>;
  @ContentChild('payeeHeaderButtonTemplate') headerButtonTemplateRef?: TemplateRef<any>;
}
