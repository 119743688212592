import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  NgModule
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomInputFieldComponent } from './components/custom-input-field/custom-input-field.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './components/dashboard/header/header.component';
import { SidebarComponent } from './components/dashboard/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login/login.component';
import { HttpErrorInterceptor } from './interceptor/http-error/http-error.interceptor';
import { IbanPipe } from './pipes/iban/iban.pipe';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { RoleGuardService } from './services/role-guard/role-guard.service';

import { PayeeAccordionModule } from 'src/app/shared/payee-accordion/payee-accordion.module';
import { PayeeAutosaveModule } from 'src/app/shared/payee-autosave/payee-autosave.module';
import { PayeeBigSwitcherModule } from 'src/app/shared/payee-big-switcher/payee-big-switcher.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeHeaderModule } from 'src/app/shared/payee-header/payee-header.module';
import { PayeeHorizontalLineModule } from 'src/app/shared/payee-horizontal-line/payee-horizontal-line.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { PayeeProgressBarModule } from 'src/app/shared/payee-progress-bar/payee-progress-bar.module';
import { PayeeRadioModule } from 'src/app/shared/payee-radio/payee-radio.module';
import { PayeeSwitcherModule } from 'src/app/shared/payee-switcher/payee-switcher.module';

import localeHu from '@angular/common/locales/hu';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { AirtableEmailTestModule } from './components/dashboard/airtable-email-test/airtable-email-test.module';
import { CasesListComponent } from './components/dashboard/cases-list/cases-list.component';
import { CasesTableModule } from './components/dashboard/cases-list/cases-table/cases-table.module';
import { ClientsListModule } from './components/dashboard/clients-list/clients-list.module';
import { EnumsComponent } from './components/dashboard/enums/enums.component';
import { ProductDiscountsModule } from './components/dashboard/product-discounts/product-discounts.module';
import { ProductPricesModule } from './components/dashboard/product-prices/product-prices.module';
import { RolesModule } from './components/dashboard/roles/roles.module';
import { WorkflowEmailTestModule } from './components/dashboard/workflow-email-test/workflow-email-test.module';
import { GetPasswordLinkSuccessComponent } from './components/forgot-password/get-password-link-success/get-password-link-success.component';
import { GetPasswordLinkComponent } from './components/forgot-password/get-password-link/get-password-link.component';
import { NewPasswordSuccessComponent } from './components/forgot-password/new-password-success/new-password-success.component';
import { NewPasswordComponent } from './components/forgot-password/new-password/new-password.component';
import { PulsatingRingComponent } from './components/pulsating-ring/pulsating-ring.component';
import { AddClaimEvidenceDialogComponent } from './helper/edit-case-dialog/add-claim-evidence-dialog/add-claim-evidence-dialog.component';
import { EditCaseDialogModule } from './helper/edit-case-dialog/edit-case-dialog.module';
import { SchemaArrayFieldEditorDialogComponent } from './helper/schema-array-field-editor-dialog/schema-array-field-editor-dialog.component';
import { WorkflowDialogModule } from './shared/workflow-dialogs/workflow-dialog.module';
import { HeaderAppComponent } from './components/headers/header-app/header-app.component';
import { HeaderEmptyComponent } from './components/headers/header-empty/header-empty.component';
import { FormTemplateModule } from './shared/screen-templates/form-template/form-template.module';
import { LoginTemplateModule } from './shared/login-template/login-template.module';
import { GeneralLoadingDialogModule } from './shared/general-loading-dialog/general-loading-dialog.module';
import { GeneralSuccessDialogModule } from './shared/general-success-dialog/general-success-dialog.module';
import { MetricsModule } from './components/dashboard/metrics/metrics.module';
import { PartnersListModule } from './components/dashboard/partners-list/partners-list.module';
import { SzamlazzhuModule } from './components/dashboard/szamlazzhu/szamlazzhu.module';
import { CampaignsModule } from './components/dashboard/campaigns/campaigns.module';
import { CopyCaseWithProductDialogModule } from './helper/copy-case-with-product-dialog/copy-case-with-product-dialog.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeHu, 'hu');

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (style) {
      case 'long': return ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
      default: return ['Vas', 'Hét', 'Kedd', 'Szer', 'Csüt', 'Pén', 'Szom'];
    }
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (style) {
      case 'long': return ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
      default: return ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szept', 'Okt', 'Nov', 'Dec'];
    }
  }
}

export const DateFormats = {
  parse: {
    dateInput: ['YYYY.MM.DD.']
  },
  display: {
    dateInput: 'YYYY.MM.DD.',
    monthYearLabel: 'YYYY.MM.DD.',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    LoginComponent,
    SidebarComponent,
    LoginComponent,
    CustomCheckboxComponent,
    PhonePipe,
    IbanPipe,
    CustomInputFieldComponent,
    PulsatingRingComponent,
    EnumsComponent,
    CasesListComponent,
    SchemaArrayFieldEditorDialogComponent,
    AddClaimEvidenceDialogComponent,
    GetPasswordLinkComponent,
    GetPasswordLinkSuccessComponent,
    NewPasswordComponent,
    NewPasswordSuccessComponent,
    HeaderAppComponent,
    HeaderEmptyComponent,
  ],
  imports: [
    AirtableEmailTestModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CampaignsModule,
    CasesTableModule,
    ClientsListModule,
    CopyCaseWithProductDialogModule,
    DragDropModule,
    EditCaseDialogModule,
    FormsModule,
    FormTemplateModule,
    GeneralLoadingDialogModule,
    GeneralSuccessDialogModule,
    HttpClientModule,
    LoginTemplateModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MetricsModule,
    PayeeAccordionModule,
    PayeeAutosaveModule,
    PayeeBigSwitcherModule,
    PayeeButtonsModule,
    PayeeDropzoneModule,
    PayeeFormFieldsModule,
    PayeeHeaderModule,
    PayeeHorizontalLineModule,
    PayeeIconModule,
    PayeeInfoModule,
    PayeeInputModule,
    PayeePopupModule,
    PayeeProgressBarModule,
    PayeeRadioModule,
    PayeeSwitcherModule,
    ProductDiscountsModule,
    ProductPricesModule,
    ReactiveFormsModule,
    RolesModule,
    RouterModule,
    WorkflowDialogModule,
    WorkflowEmailTestModule,
    PartnersListModule,
    SzamlazzhuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        deps: [HttpClient]
      },
    }),
  ],
  providers: [
    AuthGuardService,
    RoleGuardService,
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    protected authService: AuthService,
  ) { }
}
// [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}]
