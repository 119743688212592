import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeIconComponent } from './payee-icon.component';

@NgModule({
	declarations: [
		PayeeIconComponent,
	],
	imports: [
		CommonModule,
	],
	exports: [
		PayeeIconComponent,
	],
})
export class PayeeIconModule { }
