import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeRippleDirective } from './payee-ripple.directive';

@NgModule({
	declarations: [
		PayeeRippleDirective,
	],
	imports: [
		CommonModule,
	],
	exports: [
		PayeeRippleDirective,
		CommonModule,
	],
})
export class PayeeRippleModule { }
