import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientDataTableDetailsResponse, ClientDataTableItem, ClientsService } from 'src/app/services/clients/clients.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { EditClientDialogComponent } from 'src/app/components/dashboard/clients-list/edit-client-dialog/edit-client-dialog.component';
import { TitleService } from 'src/app/services/title/title.service';
import { CreateClientDialogComponent } from './create-client-dialog/create-client-dialog.component';
import { DeleteClientDialogComponent } from './delete-client-dialog/delete-client-dialog.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent implements OnDestroy {
  readonly clientFormControl = new FormControl();

  sort_by?: keyof ClientDataTableItem;
  sort_direction: 'asc' | 'desc' = 'asc';

  tableLoading = false;
  readonly dataSource = new MatTableDataSource<ClientDataTableItem>();
  readonly displayedColumns: string[] = [
    'name',
    'tax_number',
    'open_cases',
    'closed_cases',
    'actions',
    'edit',
    'delete',
  ];

  private readonly subscriptionKiller = new Subject<void>();

  // pagination
  length: number = 0;
  pageSize = 10;
  pageIndex = 0;

  expandedElement: ClientDataTableItem | null = null;
  expandedElementData: ClientDataTableDetailsResponse | null = null;

  constructor(
    private clientsService: ClientsService,
    private router: Router,
    private dialog: MatDialog,
    private titleService: TitleService,
    private route: ActivatedRoute,
  ) {
    this.titleService.setTitle('Összes hitelező');

    if (this.route.snapshot.queryParams.filter) {
      this.clientFormControl.patchValue(this.route.snapshot.queryParams.filter);
    }

    this.clientFormControl.valueChanges
      .pipe(takeUntil(this.subscriptionKiller), debounceTime(1000), distinctUntilChanged())
      .subscribe({
        next: async filter => {
          this.pageIndex = 0;
          this.router.navigate([], {
            queryParams: {
              filter,
            },
          });
          await this.setClients();
        },
      });
    this.clientFormControl.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscriptionKiller.next();
  }

  navigateToCases(client: ClientDataTableItem) {
    this.router.navigate(['/admin/cases-list'], {
      queryParams: {
        clientId: client.id,
      },
    });
  }

  async setClients() {
    try {
      this.tableLoading = true;
      const res = await this.clientsService.getClientsDataTable({
        name: this.clientFormControl.value || undefined,
        page: this.pageIndex + 1,
        per_page: this.pageSize,
        sort_by: this.sort_by as any,
        sort_direction: this.sort_direction,
      });

      this.length = res.pagination.total;
      this.dataSource.data = res.data;
    } catch (error) {
      console.log('Error while loading clients', error);
    } finally {
      this.tableLoading = false;
    }
  }

  async pageChange(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
    }
    if (event.pageIndex !== this.pageIndex) {
      this.pageIndex = event.pageIndex;
    }
    await this.setClients();
  }

  async sortData(event: Sort): Promise<void> {
    this.sort_by = event.active as keyof ClientDataTableItem;
    this.sort_direction = event.direction || 'asc';

    await this.setClients();
  }

  async updateExpandedElement(element: ClientDataTableItem): Promise<void> {
    if (this.expandedElement === element) {
      this.expandedElement = null;
      this.expandedElementData = null;
    } else {
      this.expandedElement = element;
      this.expandedElementData = await this.clientsService.getClientsDataTableDetails(element.id);
    }
  }

  editClient(event: Event, element: ClientDataTableItem) {
    event.stopPropagation();
    this.dialog.open(EditClientDialogComponent, {
      data: element,
      autoFocus: false,
      maxHeight: '90%',
    });
  }

  deleteClient(event: Event, element: ClientDataTableItem) {
    event.stopPropagation();
    const ref = this.dialog.open(DeleteClientDialogComponent, {
      data: element,
      autoFocus: false,
      maxHeight: '90%',
    });
    ref.afterClosed().pipe(first())
      .subscribe({
        next: async (deleted?: boolean) => {
          if (deleted) {
            await this.setClients();
          }
        },
      });
  }

  createClient(): void {
    const ref = this.dialog.open(CreateClientDialogComponent, {
      autoFocus: false,
      maxHeight: '90%',
    });
    ref.afterClosed().pipe(first())
      .subscribe({
        next: async (name?: string) => {
          if (name) {
            this.clientFormControl.patchValue(name);
          }
        },
      });
  }

  getIsDeletableMessage(element: ClientDataTableItem): string {
    if (element.is_deletable === true) {
      return '';
    }
    if (element.is_deletable === 'client-has-users') {
      return 'A hitelezőt nem lehet törölni, mert van hozzárendelve felhasználó.';
    }
    if (element.is_deletable === 'has-started-cases') {
      return 'A hitelezőt nem lehet törölni, mert már indított ügyet.';
    }

    return 'Az ügyet nem lehet törölni.';
  }
}
