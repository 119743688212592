import {
	Component,
	ContentChild,
	forwardRef,
	Input,
	Output,
	EventEmitter,
	TemplateRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { PayeeFormFieldComponent } from '../payee-form-field/payee-form-field.component';

@Component({
	selector: 'payee-dropdown',
	templateUrl: './payee-dropdown.component.html',
	styleUrls: ['./payee-dropdown.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PayeeDropdownComponent),
		multi: true,
	}],
})
export class PayeeDropdownComponent extends PayeeFormFieldComponent implements ControlValueAccessor {
	@Input() options: any[] = [];
	@Input() selected: any;
	@Output() selectedChange = new EventEmitter();
	@Input() disabled = false;
	@Input() displayWith: (x: any) => string = x => x;
	@Input() color = 'white';

	@ContentChild('menuDisplayTemplate') menuDisplayTemplateRef?: TemplateRef<any>;
	@ContentChild('itemDisplayTemplate') itemDisplayTemplateRef?: TemplateRef<any>;
	@ContentChild('menuButtonTemplate') menuButtonTemplateRef?: TemplateRef<any>;

	onChange: (obj: any) => void = () => {};

	constructor() {
		super();
		this.width = 'normal';
	}

	writeValue(obj: any): void {
		this.selected = obj;
	}

	registerOnChange(fn: (obj: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
