<div
  class="wrapper"
  (click)="onSelect()"
  [attr.labelPosition]="labelPosition"
  [attr.checked]="checked"
>
  <span>
    <ng-content></ng-content>
  </span>
  <button class="payee-radio-button-outer">
    <div class="payee-radio-button-inner"></div>
  </button>
</div>