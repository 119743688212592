import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[dropzone]',
})
export class PayeeDropzoneDirective {
	@Output() dropped = new EventEmitter<FileList>();
	@Output() hovered = new EventEmitter<boolean>();

	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent): void {
		const files = event.dataTransfer?.files;
		if (!files) {
			return;
		}
		event.preventDefault();
		this.dropped.emit(files);
		this.hovered.emit(false);
	}

	@HostListener('dragover', ['$event'])
	onDragOver(event: DragEvent): void {
		event.preventDefault();
		this.hovered.emit(true);
	}

	@HostListener('dragleave', ['$event'])
	onDragLeave(event: DragEvent): void {
		event.preventDefault();
		this.hovered.emit(false);
	}

}
