import { Component, Input } from '@angular/core';
import { PayeeButtonComponent } from '../payee-button/payee-button.component';

@Component({
	selector: 'button[payee-switcher-button]',
	templateUrl: './payee-switcher-button.component.html',
	styleUrls: ['./payee-switcher-button.component.scss'],
})
export class PayeeSwitcherButtonComponent extends PayeeButtonComponent {
	private static instances = 0;
  @Input() value: any;

  readonly id = PayeeSwitcherButtonComponent.instances++;
}