import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-real-estate-execution',
  templateUrl: './real-estate-execution.component.html',
  styleUrls: ['./real-estate-execution.component.scss']
})
export class RealEstateExecutionComponent {
  readonly realEstateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RealEstateExecutionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
  ) {
    this.realEstateForm = this.fb.group({
      message: ['', [Validators.required]],
    });
  }

  get message(): FormControl { return this.realEstateForm.get('message') as FormControl; }

  private readonly getParams = () => {
    return {
      message: this.message.value,
    };
  }

  async submit() {
    if (this.realEstateForm.invalid) {
      Object.values(this.realEstateForm.controls).forEach(control => control.markAsDirty());
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: this.getParams(),
    };

    this.dialogRef.close(closeValue);
  }
}
