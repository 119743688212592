import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	HostBinding,
	Input,
	OnDestroy,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
	selector: 'payee-form-field',
	templateUrl: './payee-form-field.component.html',
	styleUrls: ['./payee-form-field.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PayeeFormFieldComponent implements AfterViewInit, OnDestroy {
	@ViewChild('errorsBox') errorsBox!: ElementRef;

	@ContentChild(NgControl) formControl?: AbstractControl;
	errorObserver!: MutationObserver;

	@Input() @HostBinding('attr.width') width?: string;
	@Input() @HostBinding('attr.height') height?: string;

	get error(): boolean {
		if (!this.formControl) {
			return false;
		}
		return this.formControl.invalid && this.formControl.dirty;
	}

	ngAfterViewInit(): void {
		// Hack to make the errors box smoothly change height
		this.errorObserver = new MutationObserver((mutations) => {
			mutations.forEach(mutation => {
				const el = mutation.target as HTMLElement;
				let height = 0;
				for (let i = 0; i < el.children.length; ++i) {
					height += el.children.item(i)?.clientHeight || 0;
				}
				el.style.height = `${height}px`;
			});
		});

		this.errorObserver.observe(this.errorsBox.nativeElement, {
			attributes: true,
			childList: true,
		});
	}

	ngOnDestroy(): void {
		if (this.errorObserver) {
			this.errorObserver.disconnect();
		}
	}
}
