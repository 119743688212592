import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeStepComponent } from './payee-step/payee-step.component';
import { PayeeProgressBarComponent } from './payee-progress-bar.component';
import { PayeeProgressBarNextDirective } from './payee-progress-bar-next.directive';
import { PayeeProgressBarPrevDirective } from './payee-progress-bar-prev.directive';
import { PayeeHeaderModule } from '../payee-header/payee-header.module';
import { PayeeButtonsModule } from '../payee-buttons/payee-buttons.module';

@NgModule({
	declarations: [
		PayeeStepComponent,
		PayeeProgressBarComponent,
		PayeeProgressBarNextDirective,
		PayeeProgressBarPrevDirective,
	],
	imports: [
		CommonModule,
		PayeeHeaderModule,
		PayeeButtonsModule,
	],
	exports: [
		PayeeStepComponent,
		PayeeProgressBarComponent,
		PayeeProgressBarNextDirective,
		PayeeProgressBarPrevDirective,
		CommonModule,
	],
})
export class PayeeProgressBarModule { }
