import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

export type Installment = {
  remaining_amount: number;
};

export type DialogData = {
  installments: Installment[];
  payee_case: {
    remaining_amount: number;
    currency: string;
  };
  transition_ids: {
    partial_payment: string;
    full_payment: string;
  };
};

@Component({
  selector: 'app-installment',
  templateUrl: './installment.component.html',
  styleUrls: ['./installment.component.scss']
})
export class InstallmentComponent implements OnInit, OnDestroy {
  dialogData: DialogData;

  arePaymentDetailsVisible = false;
  readonly installmentForm: FormGroup;
  readonly installmentPaymentAmounts = new Map<Installment, number>();

  private readonly destroy = new Subject<void>();

  get paymentAmount(): FormControl { return this.installmentForm.get('paymentAmount') as FormControl; }
  get isFullPayment(): boolean {
    const remainingAmount = this.dialogData?.payee_case?.remaining_amount ?? Infinity;
    const paymentAmount = this.getNumberValueOfString(this.paymentAmount.value);

    return paymentAmount >= remainingAmount;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InstallmentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
    private snackbar: MatSnackBar,
  ) {
    this.installmentForm = this.fb.group({
      paymentAmount: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.dialogData = await this.workflowService.getDialogData({
        caseId: this.data.caseId,
        transitionId: this.data.transitionId,
      }) as DialogData;
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt az adatok betöltésekor!', 'OK', {
        data: 5000,
      });
      this.dialogRef.close();
      return;
    }

    this.paymentAmount.setValidators([
      Validators.required,
      this.minValidator(1),
      this.maxValidator(this.dialogData!.payee_case.remaining_amount),
    ]);
    this.paymentAmount.updateValueAndValidity();

    this.paymentAmount.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (value: string) => {
          let num = this.getNumberValueOfString(value);
          const formattedValue = num.toLocaleString('hu-HU').replace(/^0*/, '');

          for (const installment of this.dialogData!.installments) {
            if (installment.remaining_amount >= num) {
              this.installmentPaymentAmounts.set(installment, num);
              num = 0;
            } else {
              this.installmentPaymentAmounts.set(installment, installment.remaining_amount);
              num -= installment.remaining_amount;
            }
          }

          if (formattedValue === value) {
            return;
          }

          this.paymentAmount.patchValue(formattedValue, { emitEvent: false });
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  readonly showPaymentDetails = (areVisible: boolean): void => {
    this.arePaymentDetailsVisible = areVisible;
  };

  // TODO: type
  private readonly getParams = () => {
    return {
      amount: this.getNumberValueOfString(this.paymentAmount.value),
    };
  };

  readonly submit = async () => {
    if (this.installmentForm.invalid || !this.dialogData) {
      Object.values(this.installmentForm.controls).forEach(control => control.markAsDirty());
      return;
    }

    const transitionId = this.isFullPayment ?
      this.dialogData.transition_ids.full_payment
      : this.dialogData.transition_ids.partial_payment;
    const params = this.getParams();

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId,
      params,
    };
    this.dialogRef.close(closeValue);
  };

  private minValidator(value: number) {
    return (control: AbstractControl) => {
      if (control.value === '') {
        return null;
      }

      const num = this.getNumberValueOfString(control.value);
      if (num < value) {
        return {
          min: {
            expected: value,
            actual: num,
          },
        };
      } else {
        return null;
      }
    };
  }

  private maxValidator(value: number) {
    return (control: AbstractControl) => {
      if (control.value === '') {
        return null;
      }

      const num = this.getNumberValueOfString(control.value);
      if (num > value) {
        return {
          max: {
            expected: value,
            actual: num,
          },
        };
      } else {
        return null;
      }
    };
  }

  private getNumberValueOfString(str: string) {
    return parseInt(str.replace(/\D/g, '').slice(0, 8) || '0');
  }
}
