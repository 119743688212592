<div
  class="payee-dropzone-wrapper"
  dropzone
  (hovered)="toggleHover($event)"
  (dropped)="filesChosen($event)"
  [class.hovering]="isHovering"
  (click)="uploadClick(upload)"
>
  <div class="content">
    <div>
      <payee-icon
        [name]="environment.UPLOAD_CLOUD_ICON"
        [width]="'40px'"
        [height]="'40px'"
      ></payee-icon>
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="description">
      {{ description }}
    </div>
  </div>
  <input
    hidden
    #upload
    type="file"
    [multiple]="multiple"
    [accept]="accept"
    (change)="uploadChange($event)"
  />
</div>
