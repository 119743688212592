import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { InvoicesDataTableItem } from 'src/app/services/szamlazzhu/types';

@Component({
  selector: 'app-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss']
})
export class InvoicesTableComponent implements OnInit, OnDestroy {
  readonly dataSource = new MatTableDataSource<InvoicesDataTableItem>();
  readonly displayedColumns: string[] = [
    'invoice_number',
    'client_name',
    'client_tax_number',
    'debtor_name',
    'debtor_tax_number',
    'amount',
    'due_date_at',
    'issued_at',
  ];

  @ViewChild(MatSort, {static: true }) sort?: MatSort;

  readonly searchControl: FormControl;
  pageSize: number = 5;
  pageIndex: number = 0;
  private next: string | null = null;
  private prev: string | null = null;
  private cursor: string | null = null;
  length?: number | null = null;
  loading = false;

  private readonly setDataSubject = new Subject<void>();
  private readonly destroy = new Subject<void>();

  private get sortBy() { return this.sort?.active; }
  private get sortDirection() { return this.sort?.direction; }

  constructor(
    private szamlazzhuService: SzamlazzhuService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.searchControl = this.fb.control('');
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;

    if (this.sort) {
      if ('sort_by' in queryParams) {
        const sortBy = queryParams.sort_by;
        const sortDirection = queryParams.sort_direction;

        if (
          this.displayedColumns.includes(sortBy)
          && (!sortDirection || ['asc', 'desc'].includes(sortDirection))
        ) {
          this.sort.sort({
            disableClear: false,
            id: sortBy,
            start: sortDirection,
          });
        }
      }
    }
    if ('search' in queryParams) {
      this.searchControl.patchValue(queryParams.search);
    }

    this.setDataSubject
      .pipe(
        takeUntil(this.destroy),
        filter(() => !this.loading),
        debounceTime(500),
      )
      .subscribe({
        next: async () => await this.setData(),
      });

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe({
        next: async search => {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              search,
            },
            queryParamsHandling: 'merge',
          });
          this.pageIndex = 0;
          this.cursor = null;
          await this.setData();
        }
      });

    this.setDataSubject.next();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  sortChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sort_by: this.sortBy,
        sort_direction: this.sortDirection,
      },
      queryParamsHandling: 'merge',
    });

    this.pageIndex = 0;
    this.cursor = null;

    this.setDataSubject.next();
  }

  pageChange(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.cursor = null;
      this.pageIndex = 0;
    } else {
      if (!event.previousPageIndex || event.previousPageIndex < event.pageIndex) {
        this.cursor = this.next;
      } else {
        this.cursor = this.prev;
      }
    }
    if (event.pageIndex === 0) {
      this.cursor = null;
    }

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.setDataSubject.next();
  }

  private async setData() {
    try {
      this.loading = true;

      const result = await this.szamlazzhuService.getInvoicesDataTable({
        sort_by: this.sortBy,
        sort_direction: this.sortDirection || null,
        search: this.searchControl.value || null,
        cursor: this.cursor,
        per_page: this.pageSize,
      });
      this.dataSource.data = result.data;

      this.next = result.pagination.next;
      this.prev = result.pagination.prev;
      this.length = Infinity;
      if (!this.next) {
        this.length = this.pageSize * this.pageIndex + result.data.length;
      }
    } catch (error) {
      console.error('Error while getting invoices', error);
    } finally {
      this.loading = false;
    }
  }
}
