<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Új kézbesítési cím megadása</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      Mivel a megadott címre nem lehetett kézbesíteni a fizetési meghagyást, új
      címet kell megadnod amire a közjegyző megküldi a fizetési meghagyást.
      <span>Nincs meg az adat?</span>
    </div>
  </ng-template>

  <div
    class="old-address input-label-small"
    color="grey-medium"
  >
    <span>Jelenlegi, hibás cím:</span>&nbsp;
    <strong>{{ dialogData?.old_address }}</strong>
  </div>

  <payee-form-field
    [width]="'wider'"
    [height]="'taller'"
  >
    <textarea
      payee-input
      placeholder="Írd ide a saját szavaiddal..."
      [formControl]="newAddressForm"
    ></textarea>
  </payee-form-field>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>Új kézbesítési cím rögzítése</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
