import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Case } from 'src/types';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { dialogComponents } from 'src/app/shared/workflow-dialogs/dialog-dictionary';
import { first } from 'rxjs/operators';
import { serialize } from 'object-to-formdata';
import { Subject } from 'rxjs';

export type RunTransition = {
  caseId: string;
  transitionId: string;
  params?: any;
};

export type RunTransitionWithDialog = {
  caseId: string;
  dialog: string;
  transitionId: string;
};

export type RunTransitionResponse = {
  success: boolean;
  case: Case;
};

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  readonly afterWorkflow = new Subject<void>();

  constructor(
    private dialog: MatDialog,
  ) {
  }

  async runTransition({
    transitionId,
    caseId,
    params,
  }: RunTransition): Promise<RunTransitionResponse> {
    const formData = serialize({ params }, {
      booleansAsIntegers: true,
      indices: true,
      nullsAsUndefineds: true,
    });
    formData.append('transitionId', transitionId);

    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${environment.baseUrl}/api/transition/${caseId}`,
      data: formData,
    };
    const result = await axios(config);
    this.afterWorkflow.next();
    return result.data;
  }

  async runTransitionWithDialog({
    caseId,
    dialog,
    transitionId,
  }: RunTransitionWithDialog): Promise<RunTransitionResponse | undefined> {
    const dialogComponent = dialogComponents[dialog];
    const dialogRef = this.dialog.open(dialogComponent, {
      data: {
        caseId,
        transitionId,
      },
    });

    return await new Promise<RunTransitionResponse | undefined>((res, rej) => {
      dialogRef.afterClosed()
        .pipe(first())
        .subscribe({
          next: async (params: RunTransition | undefined) => {
            if (!params) {
              return res(undefined);
            }

            try {
              const workflowResponse = await this.runTransition(params);
              res(workflowResponse);
            } catch (error) {
              rej(error);
            }
          },
        });
    });
  }

  async getDialogData({ caseId, transitionId }: RunTransition): Promise<unknown> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: `${environment.baseUrl}/api/transition/${caseId}/${encodeURIComponent(transitionId)}`,
    };
    const result = await axios(config);
    return result.data;
  }
}
