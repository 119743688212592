<payee-form-field [style.--width]="width" [style.--height]="height">
  <span payee-label color="grey-dark">Cégnév/Név</span>
  <input
    [formControl]="nameFormControl"
    payee-input
    #nameInput
    [value]="name"
    placeholder="{{ placeholder }}"
    [matAutocomplete]="auto"
    tab-directive
    [readonly]="isSearchDisabled"
  />

  <span payee-suffix style="pointer-events: all">
    <payee-icon class="find-icon" [name]="'find-black'"></payee-icon>
  </span>

  <span
    *ngIf="isDeletable"
    payee-suffix
    style="pointer-events: all"
    (click)="onDeleteButtonClick()"
  >
    <payee-icon class="trash-icon" [name]="'trash-red'"></payee-icon>
  </span>

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <div *ngIf="nameInput.value && !isSearchDisabled">
      <div style="max-height: 240px; overflow: auto">
        <mat-option
          class="menu-item"
          *ngFor="let company of companies"
          [value]="company.name"
          (click)="fillWithCompanySearchItemValues(company)"
        >
          <div class="new-small-title">
            {{ company.name }}
          </div>
          <div class="new-small-description">
            {{ company?.postcode }} {{ company?.settlement }},
            {{ company?.street }}
          </div>
          <div class="new-small-description">
            {{ company?.tax_number }}
          </div>
        </mat-option>
        <mat-option *ngIf="companyLoading" disabled>
          <div class="flex hcenter">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="30"
            ></mat-progress-spinner>
          </div>
        </mat-option>
      </div>
      <div *ngIf="otherSelection" style="max-height: 96px">
        <mat-option
          *ngIf="!isHouseRep && canBeInd"
          class="button-container"
          [value]="nameInput.value"
          (click)="fillWithIndValues(nameInput.value)"
        >
          <div class="button">
            <div>
              <payee-icon [name]="'plus-grey-thick'"></payee-icon>
            </div>
            <div class="button-text">Magánszemélyként adom hozzá</div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="!isHouseRep && canBeHouse"
          class="button-container"
          [value]="nameInput.value"
          (click)="fillWithHouseValues(nameInput.value)"
        >
          <div class="button">
            <div>
              <payee-icon [name]="'plus-grey-thick'"></payee-icon>
            </div>
            <div class="button-text">Egyéb társasházként adom hozzá</div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="isHouseRep"
          class="button-container"
          [value]="nameInput.value"
          (click)="fillWithIndValues(nameInput.value)"
        >
          <div class="button">
            <div>
              <payee-icon [name]="'plus-grey-thick'"></payee-icon>
            </div>
            <div class="button-text">
              Magánszemély közös képviselőként adom hozzá
            </div>
          </div>
        </mat-option>
      </div>
    </div>
  </mat-autocomplete>

  <ng-container payee-error *ngIf="nameFormControl?.dirty">
    <div *ngIf="nameFormControl.hasError('required')">
      A mező kitöltése kötelező!
    </div>
  </ng-container>
</payee-form-field>
