import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'payee-dropzone',
	templateUrl: './payee-dropzone.component.html',
	styleUrls: ['./payee-dropzone.component.scss'],
})
export class PayeeDropzoneComponent {
	@Input() @HostBinding('attr.width') width?: string;
	@Input() multiple = true;
	@Input() title = 'Tölts fel egy számlát vagy egyenlegközlőt';
	@Input() description = 'Kérünk, ha számlákat töltesz fel, minden számlához külön követelést adj hozzá.';
	@Input() accept = `
						application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.ms-powerpoint,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            text/plain,
            application/vnd.ms-xpsdocument,
            image/jpg,
            image/jpeg,
            image/png,
            image/gif,
            image/bmp,
            image/tiff,
            image/svg+xml,
            image/vnd.adobe.photoshop,
            application/pdf,
            application/zip,
            application/x-rar-compressed,
            application/octet-stream,
            application/gzip,
            application/x-tar,
            application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
						`;
  @Input() isDisabled = false;
  isHovering = false;

	@Output() dropped = new EventEmitter<FileList>();

	toggleHover(event: boolean): void {
		this.isHovering = event;
	}

	uploadClick(upload: any) {
		if (!this.isDisabled) {
			upload.click();
		}
	}

	uploadChange(event: Event) {
		if (!this.isDisabled) {
			const upload = event.target as HTMLInputElement;
			if (upload.files) {
				this.filesChosen(upload.files);
			}
		}
	}

	filesChosen(files: FileList) {
		if (!this.isDisabled) {
			this.dropped.next(files);
			const upload = document.querySelector('input[type=file]') as HTMLInputElement;
			upload.value = ''; // reset the input element
		}
	}

  readonly environment = environment;
}
