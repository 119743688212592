import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CampaignsService, CampaignUserItem } from 'src/app/services/campaigns/campaigns.service';

@Component({
  selector: 'app-campaign-user-table',
  templateUrl: './campaign-user-table.component.html',
  styleUrls: ['./campaign-user-table.component.scss']
})
export class CampaignUserTableComponent implements OnInit {
  private readonly campaign: string;

  readonly dataSource = new MatTableDataSource<CampaignUserItem>();
  displayedColumns: string[] = [
    'name',
    'email',
    'payee_cases_count',
  ];

  constructor(
    private route: ActivatedRoute,
    private campaignsService: CampaignsService,
  ) {
    this.campaign = this.route.snapshot.params.campaign;
  }

  async ngOnInit(): Promise<void> {
    const result = await this.campaignsService.getUsersOfCampaign(this.campaign);
    this.dataSource.data = result.users;
  }
}
