export const environment = {
  baseUrl: 'https://api2-dev.payee.tech/admin',
  afterLoginPath: '/admin',
  production: false,

  appUrl: 'https://app2-dev.payee.tech',
  paymentUrl: 'https://payment2-dev.payee.tech',

  titleSuffix: '(Éles)',

  ARROW_DOWN_ICON: 'arrow-down-black',
  COPY_ICON: 'copy-blue',
  EXTERNAL_LINK_ICON: 'external-link-blue',
  MORE_ICON: 'more-blue',
  PLUS_ICON: 'plus-blue',
  RADIO_CHECKED_ICON: 'radio-checked-blue',
  UPLOAD_CLOUD_ICON: 'upload-cloud',
  CHECKBOX_FILLED_ICON: 'checkbox-filled',
  CHECKMARK_GREEN_CIRCLE_ICON: 'checkmark-darker-green-circle',
  TRASH_ICON: 'trash-red',
  LOGO_ICON: 'payee-logo',

  HELP_URL: 'https://help.payee.tech/hu/',
};
