import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeAutosaveComponent } from './payee-autosave.component';

@NgModule({
	declarations: [
		PayeeAutosaveComponent,
	],
	imports: [
		CommonModule,
	],
	exports: [
		PayeeAutosaveComponent,
	],
})
export class PayeeAutosaveModule { }
