import { Component, ElementRef, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'button[payee-button]',
	templateUrl: './payee-button.component.html',
	styleUrls: ['./payee-button.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PayeeButtonComponent),
		multi: true,
	}],
})
export class PayeeButtonComponent implements ControlValueAccessor {
	element: HTMLButtonElement;

	@Input() disableRipple = false;
  @Input() loading = false;

	@Input() @HostBinding('class.payee-round-button') isRoundButton = true;
	@Input() @HostBinding('class.payee-border-button') isBorderButton = false;

	@Input() @HostBinding('attr.color') color?: string;
	@Input() @HostBinding('attr.width') width?: string;
	@Input() @HostBinding('attr.height') height?: string;

	constructor(
		private ref: ElementRef<HTMLButtonElement>,
	) {
		this.element = this.ref.nativeElement;
	}

	// ControlValueAccessor
	onChange = (obj: any) => { };

	onTouched = () => { };

	writeValue(obj: any): void {
		this.onChange(obj);
	}

	setDisabledState(isDisabled: boolean): void { }

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	registerOnChange(fn: (obj: any) => void): void {
		this.onChange = fn;
	}
}
