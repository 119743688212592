import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-missed-installment-report',
  templateUrl: './missed-installment-report.component.html',
  styleUrls: ['./missed-installment-report.component.scss']
})
export class MissedInstallmentReportComponent {
  constructor(
    private dialogRef: MatDialogRef<MissedInstallmentReportComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
  ) { }

  readonly submit = async () => {
    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    };

    this.dialogRef.close(closeValue);
  }
}
