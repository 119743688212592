import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeCompanySearchInputFieldComponent } from './payee-company-search-input-field.component';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';
import { MaterialModules } from '../../material-modules';
import { PayeeFormFieldsModule } from '../payee-form-fields/payee-form-fields.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PayeeCompanySearchInputFieldComponent,
  ],
  imports: [
    CommonModule,
    MaterialModules,
    PayeeIconModule,
    PayeeFormFieldsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    PayeeCompanySearchInputFieldComponent,
  ],
})
export class PayeeCompanySearchInputFieldModule { }
