import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingDialogService } from '../../services/loading-dialog/loading-dialog.service';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-missing-data-general',
  templateUrl: './missing-data-general.component.html',
  styleUrls: ['./missing-data-general.component.scss']
})
export class MissingDataGeneralComponent {
  readonly missingDataGeneral: FormGroup;

  get message(): FormControl { return this.missingDataGeneral.get('message') as FormControl; }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MissingDataGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
    private loadingDialogService: LoadingDialogService,
  ) {
    this.missingDataGeneral = this.fb.group({
      message: ['', [Validators.required]],
    });
  }

  private readonly getParams = () => {
    return {
      message: this.message.value,
    };
  };

  async submit() {
    if (this.missingDataGeneral.invalid) {
      Object.values(this.missingDataGeneral.controls).forEach(control => control.markAsDirty());
      return;
    }

    const params = this.getParams();

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params,
    };

    this.dialogRef.close(closeValue);
  }
}
