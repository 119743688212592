import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import * as ibantools from 'ibantools';
import { Moment, isMoment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  filter = (value: any, filter: string): boolean => {
    if (!value) {
      return false;
    }

    switch (typeof value) {
      case 'bigint': return value.toString().toLowerCase().includes(filter.toLowerCase());
      case 'boolean': return false;
      case 'function': return false;
      case 'number': return value.toString().toLowerCase().includes(filter.toLowerCase());
      case 'object': return Object.values(value).some(child => this.filter(child, filter));
      case 'string': return value.toLowerCase().includes(filter.toLowerCase());
      case 'symbol': return false;
      case 'undefined': return false;
      default: return false;
    }
  };

  confirmPasswordMatch(pwd1Name: string, pwd2Name: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
      const pwd1 = formGroup.controls[pwd1Name];
      const pwd2 = formGroup.controls[pwd2Name];

      if (pwd1.value !== pwd2.value) {
        pwd2.setErrors({ confirmPasswordMatch: true });
      }
      else { pwd2.setErrors(null); }
    };
  }

  phoneNumberValidator(control: AbstractControl) {
    if (!control.value || isValidNumber(control.value)) {
      return null;
    }
    return { invalidPhoneNumber: true };
  }

  bankAccountValidator(control: AbstractControl) {
    const value = control.value;
    if (!value) {
      return null;
    }

    const electronicFormatted = ibantools.electronicFormatIBAN(value);
    if (
      !electronicFormatted ||
      (!ibantools.isValidBBAN(electronicFormatted, 'HU') && !ibantools.isValidIBAN(electronicFormatted))) {
      return {
        pattern: true,
      };
    }

    return null;
  }

  formatBankAccount(control: AbstractControl) {
    const value = control.value as string;
    if (/^\d{8}-\d{8}-\d{1,}$/g.test(value)) {
      // Already 3 section long bankAccount
      control.setValue(value.substr(0, 26));
    } else if (/^\d{9,}$/g.test(value) || /^\d{8}-\d{9,}$/g.test(value)) {
      // Reached 2nd or 3rd section
      const tmp = value.replace(/\D/g, '').substr(0, 24);
      const pieces: string[] = [];
      for (let i = 0; i < tmp.length; i += 8) {
        pieces.push(tmp.substr(i, 8));
      }
      control.setValue(pieces.join('-'));
    }
    // else we don't know the format of the bank account
  }

  formatPhone(control: AbstractControl) {
    let value = control.value.toString();
    value = value.replace(/[^\d+ ]/g, '');
    const phoneNumber = parsePhoneNumber(value, 'HU');
    const formatted = phoneNumber.formatInternational();
    control.setValue(formatted);
  }

  removeLetters(e: any): void {
    if (!e.value) { return; }

    const newValue = e.value.toString().replace(/^0+/g, '').replace(/\D/g, '');
    if (newValue !== e.value) {
      e.setValue(newValue);
    }
  }

  afterOrEqual(date: Moment): ValidatorFn {
    return control => {
      const value = control.value;
      if (!isMoment(value) || value.isSameOrAfter(date)) {
        return null;
      }
      return {
        afterOrEqual: {
          expected: date,
          actual: value,
        },
      };
    };
  }

  beforeOrEqual(date: Moment): ValidatorFn {
    return control => {
      const value = control.value;
      if (!isMoment(value) || value.isSameOrBefore(date)) {
        return null;
      }
      return {
        before: {
          expected: date,
          actual: value,
        },
      };
    };
  }

  markAllChildrenAsDirty(form: FormArray | FormGroup | FormControl | AbstractControl) {
    if (!form) {
      return;
    }
    if (form instanceof FormArray) {
      form.controls.forEach(control => this.markAllChildrenAsDirty(control));
      return;
    }
    if (form instanceof FormGroup) {
      Object.values(form.controls).forEach(control => this.markAllChildrenAsDirty(control));
      return;
    }
    form.markAsDirty();
  }
}
