import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeInputDirective } from './payee-input.directive';

@NgModule({
	declarations: [
		PayeeInputDirective,
	],
	imports: [
		CommonModule,
	],
	exports: [
		PayeeInputDirective,
		CommonModule,
	],
})
export class PayeeInputModule { }
