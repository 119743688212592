<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Befizetés rögzítése</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      Itt tudja rögzíteni az ügyben történt befizetéseket.
    </div>
  </ng-template>

  <div class="flex hcenter init-spinner" *ngIf="initLoading">
    <mat-progress-spinner
      diameter="100"
      color="accent"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>

  <form
    *ngIf="!initLoading"
    [formGroup]="manualPaymentForm"
    (submit)="submit()"
  >
    <payee-form-field width="max" height="taller">
      <input class="full-payment" payee-input formControlName="paymentAmount" />
      <span payee-suffix>
        <img
          class="flag"
          src="assets/shared/global-images/flags/HU-rectangle.svg"
        />
      </span>
      <span class="currency" payee-suffix>{{ dialogData.payee_case.currency }}</span>

      <ng-container payee-error *ngIf="paymentAmount.dirty">
        <mat-error *ngIf="paymentAmount.hasError('min')"
          >Az összegnek legalább 1 {{ dialogData.payee_case.currency }}-nak kell lennie</mat-error
        >
        <mat-error
          *ngIf="paymentAmount.hasError('max')"
        >
          Az összeg legfeljebb
          {{ paymentAmount.errors?.max?.expected | number : "1.0" : "hu" }}
          {{ dialogData?.payee_case?.currency }} lehet
        </mat-error>
        <mat-error *ngIf="paymentAmount.hasError('required')"
          >A mezőt kötelező kitölteni</mat-error
        >
      </ng-container>
    </payee-form-field>

    <div class="claim-details-container">
      <ng-container
        *ngFor="let claim of dialogData?.claims; let claimIndex = index"
      >
        <div class="claim-details flex vcenter">
          <div class="claim-number">{{ claimIndex + 1 }}. követelés:</div>
          <div class="claim-name">{{ claim.claim_type_label }}</div>
          <div class="claim-value">
            {{ claim.remaining_amount | number : "1.0" : "hu" }}
            {{ dialogData?.payee_case?.currency }}
          </div>
        </div>

        <ng-container
          *ngFor="
            let optionalClaim of claim.optional_claims;
            let optionalClaimIndex = index
          "
        >
          <div
            class="optional-claim-details flex vcenter"
            [class.is-paid]="claim.is_paid"
          >
            <div class="optional-claim-name">
              {{ optionalClaim.claim_type_label }}
            </div>
            <div class="optional-claim-value">
              {{ optionalClaim.remaining_amount | number : "1.0" : "hu" }}
              {{ dialogData?.payee_case?.currency }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <payee-horizontal-line style="margin: 0px; background-color: black;"></payee-horizontal-line>

      <div class="claim-details flex vcenter">
        <div class="claim-number">Összesen:</div>
        <div class="claim-name"></div>
        <div class="claim-value">
          {{ dialogData?.payee_case?.remaining_amount | number : "1.0" : "hu" }}
          {{ dialogData?.payee_case?.currency }}
        </div>
      </div>
    </div>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="max"
      height="normal"
      [disabled]="manualPaymentForm.invalid"
      (click)="submit()"
    >
      <ng-container *ngIf="!isFullPayment">Rögzítem a befizetést</ng-container>
      <ng-container *ngIf="isFullPayment">Teljes ügyet kifizették</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="max"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
