<app-header-app xLink="/login"></app-header-app>
<app-form-template>
  <ng-template #titleTemplate>
    <div class="flex vcenter column title-wrapper">
      <img src="assets/shared/global-images/icons/checkmark-circle-green-big.svg" alt="checkmark-green-circle-big">
      <h1>Küldtük egy linket az új jelszóhoz!</h1>
    </div>
  </ng-template>

  <span class="description">
    Már csak egy lépés van hátra. Küldtünk egy <strong>emailt,</strong>
    amiben van egy link az új jelszó beállításához.
    Kérlek, kattints rá és add meg az új jelszavadat.
  </span>

  <payee-horizontal-line></payee-horizontal-line>

  <div class="flex vcenter hcenter">
    <span>Nem érkezett meg az email?</span>&nbsp;
    <a class="reset-link" color="blue-payee" (click)="resendForgotPasswordEmail()">Újraküldés</a>
    <ng-container *ngIf="loading">
      &nbsp;
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="16">
      </mat-progress-spinner>
    </ng-container>
  </div>
</app-form-template>