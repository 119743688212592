<div class="payee-big-switcher-button-wrapper">
  <ng-template #contentTemplate>
    <div class="default-content-wrapper">
      <h2 class="title">{{title}}</h2>
      <div class="input-label-small description">{{description}}</div>
    </div>
  </ng-template>
  <ng-container
    [ngTemplateOutlet]="contentTemplateRef || contentTemplate"
    [ngTemplateOutletContext]="{ $implicit: selected }"
  ></ng-container>
</div>
<span
  payee-ripple
  [payeeRippleDisabled]="disableRipple"
  [payeeRippleTrigger]="element"
  [attr.color]="color"
></span>