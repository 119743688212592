import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkflowService, RunTransition } from 'src/app/shared/services/workflow/workflow.service';
import { dialogComponents } from '../dialog-dictionary';

export type DialogData = {
  old_address: string;
};

@Component({
  selector: 'app-add-delivery-address',
  templateUrl: './add-delivery-address.component.html',
  styleUrls: ['./add-delivery-address.component.scss']
})
export class AddDeliveryAddressComponent implements OnInit {
  readonly dialogId: string;
  readonly newAddressForm: FormControl;
  dialogData?: DialogData;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddDeliveryAddressComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
  ) {
    this.dialogId = Object.entries(dialogComponents)
      .find(([_, comp]) => comp === AddDeliveryAddressComponent)![0];
    this.newAddressForm = this.fb.control('', [Validators.required]);
  }

  async ngOnInit(): Promise<void> {
    this.dialogData = await this.workflowService.getDialogData({
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    }) as DialogData;
  }

  submit() {
    if (this.newAddressForm.invalid) {
      this.newAddressForm.markAsDirty();
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: this.getParams(),
    };

    this.dialogRef.close(closeValue);
  }

  private readonly getParams = () => {
    return {
      message: this.newAddressForm.value,
    };
  };
}
