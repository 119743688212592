<div class="payee-popup-wrapper">
  <ng-template #titleTemplate>
    <div class="title">
      <h1>{{title}}</h1>
    </div>
  </ng-template>
  <ng-template #descriptionTemplate>
    <div class="description">
      <span color="grey-medium">{{description}}</span>
    </div>
  </ng-template>

  <ng-container [ngTemplateOutlet]="titleTemplateRef || titleTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="descriptionTemplateRef || descriptionTemplate"></ng-container>

  <ng-content></ng-content>

  <ng-template #submitTemplate>
    <ng-container *ngIf="onSubmit">
      <button payee-button width="wide" (click)="onSubmit()">Rendben</button>
    </ng-container>
  </ng-template>
  <ng-template #cancelTemplate>
    <ng-container *ngIf="onCancel">
      <button payee-button color="white" width="wide" [isBorderButton]="true" (click)="onCancel()">Mégse</button>
    </ng-container>
    <ng-container *ngIf="!onCancel">
      <button payee-button color="white" width="wide" [isBorderButton]="true" mat-dialog-close>Mégse</button>
    </ng-container>
  </ng-template>

  <div class="buttons-box">
    <ng-container [ngTemplateOutlet]="submitTemplateRef || submitTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="cancelTemplateRef || cancelTemplate"></ng-container>
  </div>
</div>