<payee-popup title="Event indítása">
  <span>TransitionId: {{ data?.transitionId }}</span>
  <span>Case ID: {{ data?.caseId }}</span>

  <ng-template #payeePopupSubmitTemplate>
    <button payee-button color="alert-green" width="wide" (click)="submit()">
      Indítás
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Mégse
    </button>
  </ng-template>
</payee-popup>
