import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { PayeeBigSwitcherButtonComponent } from '../payee-buttons/payee-big-switcher-button/payee-big-switcher-button.component';

@Component({
	selector: 'payee-big-switcher',
	templateUrl: './payee-big-switcher.component.html',
	styleUrls: ['./payee-big-switcher.component.scss'],
})
export class PayeeBigSwitcherComponent {
	@Input() columns = 1;

	readonly gap = 15;
	get basis() {
		const percentPerColumns = 100 / this.columns;
		const gapSpace = (this.columns - 1) * this.gap;
		return `calc(${percentPerColumns}% - ${gapSpace / this.columns}px)`;
	}

	private _selected?: PayeeBigSwitcherButtonComponent;
	@Input() set selected(selected: PayeeBigSwitcherButtonComponent | undefined) {
		this._selected = selected;
		this.selectedChange.emit(selected);
		this.value = selected?.value;
		setTimeout(() => this.updateButtons(), 0);
	}
	get selected() { return this._selected; }
	@Output() selectedChange = new EventEmitter<any>();

	private _value?: any;
	@Input() set value(value: any) {
		setTimeout(() => {
			this.valueChange.emit(value);
			this._value = value;
			this.selectButtonWithValue(value);
		}, 0);
	}
	get value() { return this._value; }
	@Output() valueChange = new EventEmitter<any>();

	private _buttons!: QueryList<PayeeBigSwitcherButtonComponent>;
	@ContentChildren(PayeeBigSwitcherButtonComponent) set buttons(buttons: QueryList<PayeeBigSwitcherButtonComponent>) {
		this._buttons = buttons;
		if (!this.selected && buttons.length > 0) {
			this.selected = buttons.first;
		}

		buttons.forEach(button => {
			button.element.addEventListener('click', () => this.selected = button);
		});
	}
	get buttons() { return this._buttons; }

	constructor() {
		setTimeout(() => this.updateButtons(), 0);
	}

	private updateButtons() {
		this.buttons.forEach(button => {
			button.selected = button === this.selected;
		});
	}

	private selectButtonWithValue(value = this.value) {
		if (this.selected && this.selected.value === value) {
			return;
		}
		const buttons = this.buttons.toArray();
		for (const button of buttons) {
			if (button.value === value) {
				this.selected = button;
				return;
			}
		}
	}
}
