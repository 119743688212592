<payee-popup description="Az eseményt rögzítettük az üggyel kapcsolatban.">
  <ng-template #payeePopupTitleTemplate>
    <div class="title flex column vcenter">
      <payee-icon
        class="title-icon"
        name="big-checkmark-green-circle"
        height="80px"
        width="80px"
      >
      </payee-icon>
      <h1>Sikeresen rögzítve!</h1>
    </div>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button color="blue-payee"
      [isRoundButton]="false"
      width="wide"
      id="confirm-button"
      mat-dialog-close
    >
      Rendben
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
  </ng-template>
</payee-popup>
