import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

export type DialogData = {
  transition_ids: {
    close: string;
    full_payment: string;
  };
  claim_uuids: string[];
  amount: number;
};

@Component({
  selector: 'app-close-case',
  templateUrl: './close-case.component.html',
  styleUrls: ['./close-case.component.scss']
})
export class CloseCaseComponent implements OnInit {
  dialogData?: DialogData;

  constructor(
    private dialogRef: MatDialogRef<CloseCaseComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
  ) { }

  async ngOnInit() {
    this.dialogData = await this.workflowService.getDialogData({
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    }) as DialogData;
  }

  readonly cancel = () => {
    this.dialogRef.close();
  };

  readonly submit = async (type: 'paid' | 'cancel') => {
    if (!this.dialogData) {
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: type === 'cancel' ?
        this.dialogData.transition_ids.close
        : this.dialogData.transition_ids.full_payment,
      params: type === 'cancel' ? undefined : {
        claim_uuids: this.dialogData.claim_uuids,
        amount: this.dialogData.amount,
      },
    };

    this.dialogRef.close(closeValue);
  };
}
