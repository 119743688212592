<div class="progress-bar-wrapper flex column">
  <!-- <app-header-app [xLink]="xLink"> -->
  <payee-header>
    <ng-template #payeeHeaderLogoTemplate *ngIf="headerLogoTemplateRef">
      <ng-container [ngTemplateOutlet]="headerLogoTemplateRef"></ng-container>
    </ng-template>
    <ng-template #payeeHeaderButtonTemplate *ngIf="headerButtonTemplateRef">
      <ng-container [ngTemplateOutlet]="headerButtonTemplateRef"></ng-container>
    </ng-template>

    <div class="progress-bar-labels flex column vcenter hcenter">
      <!-- Progress bar -->
      <div class="progress-bar-lines flex">
        <div class="progress-bar-selector-line" #selectorLine>
          <div class="progress-bar-selector-circle"></div>
        </div>
        <div *ngFor="let step of steps.toArray().slice(1);let i=index" class="progress-bar-line"></div>
      </div>
  
      <!-- Labels -->
      <ng-template #stepLabel let-index="index" let-step>
        <div class="progress-bar-text-active" color="blue-king" *ngIf="selectedIndex === index">{{step.label || index + 1}}</div>
        <div class="progress-bar-text" color="grey-medium" *ngIf="selectedIndex !== index">{{step.label || index + 1}}</div>
      </ng-template>
  
      <div class="flex">
        <button
          payee-button *ngFor="let step of steps; index as i"
          width="shorter" class="progress-bar-button"
          [isRoundButton]="false" color="white"
          (click)="selectedIndex = i"
          [disabled]="i > indexOfLastEnabledStep"
        >
          <ng-container
            [ngTemplateOutlet]="step.stepLabelRef || stepLabel"
            [ngTemplateOutletContext]="{ $implicit: step, index: i, selected: selectedIndex === i }"
          ></ng-container>
        </button>
      </div>
    </div>
  </payee-header>
  <div class="progress-bar-content flex grow1">
    <div class="steps flex hcenter grow1">
      <ng-content select="payee-step"></ng-content>
    </div>
    <div class="global">
      <ng-content></ng-content>
    </div>
  </div>
</div>