<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <img
      src="assets/shared/global-images/icons/cross-grey-circle.svg"
      height="71.43px"
    />
    <h1>Miért állítod le az ügyet?</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <span class="description">
      A leállítás után nem tudod visszaállítani, és a Lezárt ügyek közé kerül.
    </span>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit('paid')"
    >
      <ng-container>A teljes követelésemet megfizették</ng-container>
    </button>
    <button
      class="payee-button"
      payee-button
      color="grey-dark"
      width="wide"
      height="normal"
      (click)="submit('cancel')"
    >
      <ng-container>Egyéb okból állítom le</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      (click)="cancel()"
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
