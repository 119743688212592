export default {
  CASE_EVENTS: {
    SHOW_ALL_EVENTS: 'Összes esemény mutatása',
    SHOW_LESS_EVENTS: 'Kevesebb esemény mutatása',
    PAYMENTS_OF_EVENT: 'Az eseményhez kapcsolódó befizetések:',
    DOCUMENTS_OF_EVENT: 'Az eseményhez kapcsolódó dokumentumok:',
    OK: 'Ok',
    ERROR_MESSAGES: {
      UNKNOWN_ERROR_WHILE_LOADING_EVENTS: 'Valami hiba történt az események betöltésekor!',
    }
  },
};