import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Moment } from 'moment';
import { claimType, legalRelationship, partyType, representativeType } from 'src/app/components/dashboard/enums/enum-descriptors';
import { CasesService, GetEditCaseResponse, UpdateCaseParams } from 'src/app/services/cases/cases.service';
import { CountriesService, Country } from 'src/app/services/countries/countries.service';
import { EnumsService } from 'src/app/services/enums/enums.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ClaimType, LegalRelationship, PartyType, RepresentativeType } from 'src/types';

export interface PayeeAbstractControl<T> extends AbstractControl {
  value: T;
  patchValue: (data: Partial<T>) => void;
  setValue: (data: T) => void;
}
export interface PayeeFormGroup<T extends Record<string, any>> extends FormGroup {
  controls: Record<keyof T, PayeeAbstractControl<T[keyof T]>>;
  value: T;

  get(path: keyof T & string): PayeeAbstractControl<T[keyof T]>;
  patchValue(data: Partial<T>): void;
  setValue(data: T): void;
}
export interface PayeeFormArray<T extends AbstractControl> extends FormArray {
  controls: T[];

  at(index: number): T;
  patchValue(data: Partial<T['value']>[]): void;
  setValue(data: T['value'][]): void;
}

export type CaseForm = {
  files: ({
    id: number;
    file_name: string;
    storage_url: string;
  } | File)[];
  legalRelationshipTypeId: number;
  legalRelationshipSpecification: string;
  description: string;
};
export type CaseFormGroup = PayeeFormGroup<CaseForm>;

export type ClaimForm = {
  id: string;
  files: {
    id: number;
    file_name: string;
    storage_url: string | File;
    invoice_number: string | null;
    invoice_date: string | Moment | null;
  }[];
  dueDateAt: string | Moment;
  originalAmount: number;
  claimType: number | null;
  claimTypeSpecification: string | null;
};
export type ClaimFormGroup = PayeeFormGroup<ClaimForm>;
export type ClaimFormArray = PayeeFormArray<ClaimFormGroup>;

export type RepresentativeForm = {
  representative_type_id: null | number;
  email?: string;
  phone?: string;
};
export type RepresentativeFormGroup = PayeeFormGroup<RepresentativeForm>;
export type ClientForm = {
  name: string | null;
  party_type_id: null | number;
  email?: string;
  phone?: string;
  representative: RepresentativeForm;
  country: number;
  postcode: string;
  settlement: string;
  street: string;
};
export type ClientFormGroup = PayeeFormGroup<ClientForm>;

export type DebtorForm = {
  id: string;
  party_type_id: null | number;
  email?: string;
  phone?: string;
  representative: RepresentativeForm;
  country: number;
  postcode: string;
  settlement: string;
  street: string;
};
export type DebtorFormGroup = PayeeFormGroup<DebtorForm>;
export type DebtorFormArray = PayeeFormArray<DebtorFormGroup>;

@Component({
  selector: 'app-edit-case-dialog',
  templateUrl: './edit-case-dialog.component.html',
  styleUrls: ['./edit-case-dialog.component.scss']
})
export class EditCaseDialogComponent implements OnInit {
  readonly formGroup: FormGroup;
  public data!: GetEditCaseResponse;
  loading = true;
  initialized = false;

  legalRelationships: LegalRelationship[] = [];
  claimTypes: ClaimType[] = [];
  partyTypes: PartyType[] = [];
  representativeTypes: RepresentativeType[] = [];
  countries: Country[] = [];

  get payeeCase(): CaseFormGroup { return this.formGroup.get('payeeCase') as CaseFormGroup; }
  get claims(): ClaimFormArray { return this.formGroup.get('claims') as ClaimFormArray; }
  get debtors(): DebtorFormArray { return this.formGroup.get('debtors') as DebtorFormArray; }
  get client(): ClientFormGroup { return this.formGroup.get('client') as ClientFormGroup; }

  get debtorTabLabel(): string { return this.debtors.length > 1 ? 'Adósok' : 'Adós'; };

  constructor(
    public dialogRef: MatDialogRef<EditCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public caseId: string,
    private fb: FormBuilder,
    private casesService: CasesService,
    private enumsService: EnumsService,
    private snackbar: MatSnackBar,
    private helpersService: HelpersService,
    private countriesService: CountriesService,
  ) {
    this.formGroup = this.fb.group({
      payeeCase: this.fb.group({
        files: this.fb.array([]),
        legalRelationshipTypeId: [null],
        legalRelationshipSpecification: '',
        description: '',
      }),
      claims: this.fb.array([], Validators.required),
      debtors: this.fb.array([], Validators.required),
      client: this.fb.group({
        name: null,
        party_type_id: [null, Validators.required],
        email: null,
        phone: null,
        representative: this.fb.group({
          representative_type_id: null,
          email: null,
          phone: null,
        }),
        country: this.fb.control('HU', Validators.required),
        postcode: this.fb.control('', Validators.required),
        settlement: this.fb.control('', Validators.required),
        street: this.fb.control('', Validators.required),
      }),
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.loading = true;

      await Promise.all([
        this.setEnums(),
        this.casesService.getEditCaseData(this.caseId).then(res => this.data = res),
        this.setCountries(),
      ]);

      if (this.data.payee_case.product_type === 'hard_1') {
        this.payeeCase.controls.files.addValidators(Validators.required);
        this.payeeCase.controls.legalRelationshipTypeId.addValidators(Validators.required);
      }

      this.resetAllForms();
      this.initialized = true;
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt az ügy betöltésekor!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.loading = false;
    }
  }

  async submit(): Promise<void> {
    try {
      const clientType = this.partyTypes.find(pt => pt.id === this.client.value.party_type_id);

      this.data = await this.casesService.updateCase(this.caseId, {
        claims: this.claims.controls.map(({ value }) => {
          const file = value.files[0];
          const isAlreadyUploaded = typeof file?.storage_url === 'string';

          const dueDateAt = value.dueDateAt;
          const invoiceDate = file?.invoice_date;

          return {
            amount: value.originalAmount,
            claim_type_id: value.claimType as number,
            claim_type_specification: value.claimTypeSpecification || undefined,
            due_date_at: typeof dueDateAt === 'string' ? dueDateAt : dueDateAt.toISOString(),
            id: value.id || undefined,
            file: isAlreadyUploaded ? undefined : file?.storage_url as File,
            file_uuid: file?.id || undefined,
            invoice_date: typeof invoiceDate === 'string' ? invoiceDate : invoiceDate?.toISOString(),
            invoice_number: file?.invoice_number as string,
          };
        }),
        debtors: this.debtors.controls.map<UpdateCaseParams["debtors"][number]>(({ value }) => {
          return {
            id: value.id,
            party_type_id: value.party_type_id as number,
            email: value.email || undefined,
            phone: value.phone || undefined,
            representative: value.representative.representative_type_id ? {
              email: value.representative.email || undefined,
              phone: value.representative.phone || undefined,
              representative_type_id: value.representative.representative_type_id as number,
            } : undefined,
            address: {
              country_id: value.country,
              postcode: value.postcode,
              settlement: value.settlement,
              street: value.street,
            },
          };
        }),
        client: {
          name: this.client.value.name || undefined,
          email: this.client.value.email || undefined,
          phone: this.client.value.phone || undefined,
          party_type_id: this.client.value.party_type_id as number,
          representative: (clientType?.type === 'org' && this.client.value.representative.representative_type_id) ? {
            email: this.client.value.representative.email || undefined,
            phone: this.client.value.representative.phone || undefined,
            representative_type_id: this.client.value.representative.representative_type_id,
          } : undefined,
          address: {
            country_id: this.client.value.country,
            postcode: this.client.value.postcode,
            settlement: this.client.value.settlement,
            street: this.client.value.street,
          },
        },
        description: this.payeeCase.value.description || undefined,
        legal_relationship_id: this.payeeCase.value.legalRelationshipTypeId as number || undefined,
        legal_relationship_specification: this.payeeCase.value.legalRelationshipSpecification || undefined,
        files: this.payeeCase.value.files.map(file => ({
          file: file instanceof File ? file : undefined,
          file_name: file instanceof File ? undefined : file.file_name,
          uuid: file instanceof File ? undefined : file.id,
        })),
      });

      this.resetAllForms();

      this.snackbar.open('Sikeres mentés', 'OK', {
        duration: 2500,
      });

      this.dialogRef.close(this.data);
    } catch (error) {
      console.error('Error while updating case', error);
      this.snackbar.open('Valami hiba történt mentés közben!', 'OK', {
        duration: 5000,
      });
      this.helpersService.markAllChildrenAsDirty(this.formGroup);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  readonly addClaimFile = (claimForm: ClaimFormGroup, file: ClaimForm['files'][number] | File): PayeeFormGroup<ClaimForm['files'][number]> => {
    const files = claimForm.get('files') as FormArray;
    if (file instanceof File) {
      const form: Record<keyof ClaimForm['files'][number], AbstractControl> = {
        id: this.fb.control(null),
        file_name: this.fb.control(file.name),
        storage_url: this.fb.control(file),
        invoice_date: this.fb.control(null, Validators.required),
        invoice_number: this.fb.control(null, Validators.required),
      };
      const formGroup = this.fb.group(form) as PayeeFormGroup<ClaimForm['files'][number]>;
      files.push(formGroup);
      return formGroup;
    } else {
      const form: Record<keyof ClaimForm['files'][number], AbstractControl> = {
        id: this.fb.control(file.id),
        file_name: this.fb.control(file.file_name),
        storage_url: this.fb.control(file.storage_url),
        invoice_date: this.fb.control(file.invoice_date, Validators.required),
        invoice_number: this.fb.control(file.invoice_number, Validators.required),
      };
      const formGroup = this.fb.group(form) as PayeeFormGroup<ClaimForm['files'][number]>;
      files.push(formGroup);
      return formGroup;
    }
  };

  addClaim = (claim?: GetEditCaseResponse['claims'][number]): void => {
    const claimForm: Record<keyof ClaimForm, AbstractControl> = {
      claimType: this.fb.control(claim?.claim_type_id, Validators.required),
      claimTypeSpecification: this.fb.control(claim?.claim_type_specification),
      dueDateAt: this.fb.control(claim?.due_date_at, Validators.required),
      files: this.fb.array([]),
      id: this.fb.control(claim?.id),
      originalAmount: this.fb.control(claim?.original_amount, [Validators.max(3E7)]),
    };
    const formGroup = this.fb.group(claimForm) as ClaimFormGroup;
    if (claim) {
      claim.files.forEach(file => this.addClaimFile(formGroup, file));
    }
    this.claims.push(formGroup);
  };

  private async setEnums(): Promise<void> {
    const [
      legalRelationships,
      claimTypes,
      partyTypes,
      representativeTypes,
    ] = await Promise.all([
      this.enumsService.getEnum<LegalRelationship>(legalRelationship.model),
      this.enumsService.getEnum<ClaimType>(claimType.model),
      this.enumsService.getEnum<PartyType>(partyType.model),
      this.enumsService.getEnum<RepresentativeType>(representativeType.model),
    ]);
    this.legalRelationships = legalRelationships;
    this.claimTypes = claimTypes;
    this.partyTypes = partyTypes;
    this.representativeTypes = representativeTypes;
  }

  private resetAllForms(): void {
    this.resetCaseForm();
    this.resetClaimForm();
    this.resetDebtorForm();
    this.resetClientForm();
  }

  private resetCaseForm(): void {
    const caseForm: Partial<CaseForm> = {
      description: this.data.payee_case.description,
      legalRelationshipSpecification: this.data.payee_case.legal_relationship_specification,
      legalRelationshipTypeId: this.data.payee_case.legal_relationship_id,
    };
    const files = this.payeeCase.get('files') as FormArray;
    files.clear();
    this.data.payee_case.legal_relationship_files.forEach(file => {
      files.push(this.fb.control(file));
    });
    this.payeeCase.patchValue(caseForm);
  }

  private resetClaimForm(): void {
    this.claims.clear();
    this.data.claims.forEach(claim => this.addClaim(claim));
  }

  private resetDebtorForm(): void {
    this.debtors.clear();
    this.data.debtors.forEach(debtor => {
      const repForm: Record<keyof RepresentativeForm, AbstractControl> = {
        email: this.fb.control(debtor.representative.email),
        phone: this.fb.control(debtor.representative.phone),
        representative_type_id: this.fb.control(
          debtor.representative.representative_type_id,
          debtor.representative.name ? Validators.required : null,
        ),
      };
      const debtorForm: Record<keyof DebtorForm, AbstractControl> = {
        id: this.fb.control(debtor.id, Validators.required),
        email: this.fb.control(debtor.email),
        phone: this.fb.control(debtor.phone),
        party_type_id: this.fb.control(debtor.party_type_id, Validators.required),
        representative: this.fb.group(repForm),
        country: this.fb.control(debtor.address.country_id ?? 'HU', Validators.required),
        postcode: this.fb.control(debtor.address.postcode, Validators.required),
        settlement: this.fb.control(debtor.address.settlement, Validators.required),
        street: this.fb.control(debtor.address.street, Validators.required),
      };
      this.debtors.push(this.fb.group(debtorForm));
    });
  }

  private resetClientForm(): void {
    const clientForm: ClientForm = {
      name: this.data.client.name,
      email: this.data.client.email || '',
      party_type_id: this.data.client.party_type_id,
      phone: this.data.client.phone || '',
      representative: {
        email: this.data.client.representative.email || '',
        phone: this.data.client.representative.phone || '',
        representative_type_id: this.data.client.representative.representative_type_id,
      },
      country: this.data.client.address.country_id ?? -1,
      postcode: this.data.client.address.postcode ?? '',
      settlement: this.data.client.address.settlement ?? '',
      street: this.data.client.address.street ?? '',
    };
    this.client.patchValue(clientForm);
  }

  private async setCountries(): Promise<void> {
    const result = await this.countriesService.getCountries();
    this.countries = result.countries;
  }
}
